import { convertObjToQueryStr } from "@utils/utils"
import { api } from "./api"
import store from "@/store"
import _ from "lodash"

export const groupApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getGroupById: builder.query({
			query: (groupId) => `/group/${groupId}`,
			providesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg }]
			}
		}),
		getGroups: builder.query({
			query: (queryObj) => `/group${convertObjToQueryStr(queryObj)}`,
			providesTags: (result, error, arg) => {
				if (error)
					return []
				let arr = []
				if (result.groups)
					arr = arr.concat(result.groups.map(doc => ({type: "Group", id: doc._id}))).concat([{type: "Group", id: "LIST"}])
				if (result.rootGroups)
					arr = arr.concat(result.rootGroups.map(doc => ({type: "RootGroup", id: doc._id}))).concat([{type: "RootGroup", id: "LIST"}])
				return arr
			}
		}),
		searchGroups: builder.query({
			query: (queryObj) => `/group/search${convertObjToQueryStr(queryObj.query ?? queryObj)}`,
			providesTags: (result, error, arg) => {
				return ["Group"]
			}
		}),
		getGroupHierarchy: builder.query({
			query: () => "/group/hierarchy",
		}),
		// Variable response depending on query param
		getMyGroups: builder.query({
			query: (queryObj) => `/group/own${convertObjToQueryStr(queryObj)}`,
			providesTags: (result, error, arg) => {
				if (error)
					return []
				if (result.groups)
					return result.groups.map(doc => ({type: "Group", id: doc._id})).concat([{type: "Group", id: "LIST"}])
				else
					return result.map(doc => ({ type: "Group", id: doc._id })).concat([{type: "Group", id: "LIST"}])
			}
		}),
		getMyRootGroups: builder.query({
			query: () => "/root_group/own",
			providesTags: (result, error, args) => {
				return result.map(doc => ({type: "RootGroup", id: doc._id})).concat([{type: "RootGroup", id: "LIST"}])
			}
		}),
		getRootGroups: builder.query({
			query: (queryObj) => `/root_group${convertObjToQueryStr(queryObj)}`,
			providesTags: (result, error, args) => {
				return result.map(doc => ({ type: "RootGroup", id: doc._id })).concat([{type: "RootGroup", id: "LIST"}])
			}
		}),
		getRootGroupsByProcess: builder.query({
			query: (rootGroupId) => `/root_group/process/${rootGroupId}`
		}),
		searchRootGroups: builder.query({
			query: (queryObj) => {
				if (queryObj.query) {
					queryObj = queryObj.query
				}
				return `/root_group/search${convertObjToQueryStr(queryObj)}`
			},
			providesTags: (result, error, arg) => {
				return ["RootGroup", "Group"]
			}
		}),
		groupsByRoot: builder.query({
			query: (queryObj) => "/group/byRoot",
			providesTags: (result, error, args) => {
				return result.map(doc => ({ type: "RootGroup", id: doc._id })).concat([{type: "RootGroup", id: "LIST"}])
			}
		}),
		getRootGroup: builder.query({
			query: ({ id, paginationOpts }) => `/root_group/${id}${convertObjToQueryStr(paginationOpts)}`,
			providesTags: (result, error, args) => {
				return [{type: "RootGroup", id: args.id}, "RootGroup"]
			}
		}),
		createRootGroup: builder.mutation({
			query: (body) => ({
				url: "/root_group",
				method: "POST",
				body: body
			}),
			invalidatesTags: (result, error, args) => {
				return [{type: "RootGroup", id:"LIST"}]
			}
		}),
		updateRootGroup: builder.mutation({
			query: ({id, body}) => ({
				url: `/root_group/${id}`,
				method: "PUT",
				body: body
			}),
			invalidatesTags: (result, error, args) => {
				return [{type: "RootGroup", id: args.id}]
			}
		}),
		getRootGroupClubSupportPlayers: builder.query({
			query: ({id}) => ({
				url: `/root_group/${id}/club-support/players`,
				method: "GET",
			}),
			providesTags: (result, error, args) => ([{ type: "ClubSupportPlayers", id: args.id }])
		}),
		updateRootGroupClubSupportPlayers: builder.mutation({
			query: ({id, body, query}) => ({
				url: `/root_group/${id}/club-support/players`,
				method: "PUT",
				body: body,
				params: query
			}),
			invalidatesTags: (result, error, args) => {
				return [{type: "ClubSupportPlayers", id: args.id}]
			}
		}),
		unlockRootGroupClubSupportPlayersByField: builder.mutation({
			query: ({id, body, query}) => ({
				url: `/root_group/${id}/club-support/players/unlock`,
				method: "POST",
				body: body,
				params: query
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "ClubSupportPlayers", id: args.id }]
			}
		}),
		deleteRootGroup: builder.mutation({
			query: (id) => ({
				url: `/root_group/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, args) => {
				return [{type: "RootGroup", id: args.id}]
			}
		}),
		getSubGroups: builder.query({
			query: (id) => `/root_group/${id}/groups`,
			providesTags: (result, error, arg) => {
				return result.map(doc => ({type: "Group", id: doc._id})).concat([{type: "Group", id: "LIST"}]).concat([{type: "RootGroup", id: arg}])
			}
		}),
		getLocations: builder.query({
			query: (rootGroupId) => `/location${rootGroupId ? "/group/"+rootGroupId : ""}`,
			providesTags: () => ["Location"],
		}),
		addLocation: builder.mutation({
			query: ({ groupId, rootGroupId, name }) => ({
				url: "/location",
				method: "POST",
				body: { groupId, rootGroupId, name },
			}),
			invalidatesTags: () => ["Location"]
		}),
		removeLocation: builder.mutation({
			query: (id) => ({
				url: "/location/"+id,
				method: "DELETE"
			}),
			invalidatesTags: () => ["Location"]
		}),
		createGroup: builder.mutation({
			query: (body) => ({
				url: "/group",
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{type: "Group", id: "LIST"}, { type: "RootGroup", id: result.root?._id ?? result.root }, { type: "Profile", id: store.getState().user.profile }]
			}
		}),
		updateGroup: builder.mutation({
			query: ({ groupId, body }) => ({
				url: `/group/${groupId}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: result.root?._id ?? result.root }]
			}
		}),
		deleteGroup: builder.mutation({
			query: (groupId) => ({
				url: `/group/${groupId}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg }]
			}
		}),
		deleteGroupWithoutInvalidation: builder.mutation({
			query: (groupId) => ({
				url: `/group/${groupId}`,
				method: "DELETE",
			})
		}),
		setDiaryVisibility: builder.mutation({
			query: ({ groupId, value }) => ({
				url: `/group/${groupId}/diaryVisibility`,
				method: "PUT",
				body: { value }
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }]
			}
		}),
		addGroupRole: builder.mutation({
			query: ({ groupId, body }) => ({
				url: `/group/${groupId}/role`,
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: arg.groupId }]
			}
		}),
		updateGroupRole: builder.mutation({
			query: ({ groupId, body }) => ({
				url: `/group/${groupId}/role`,
				method: "PUT",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: arg.groupId }]
			}
		}),
		deleteGroupRole: builder.mutation({
			query: ({ groupId, targetName }) => ({
				url: `/group/${groupId}/role`,
				method: "DELETE",
				body: { targetName },
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: arg.groupId }]
			}
		}),
		addGroupMemberMulti: builder.mutation({
			query: ({ body }) => ({
				url: "/group/member",
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				const profileIds = arg.body?.profileIds ?? []
				const groupIds = arg.body?.groupIds ?? []
				const rootGroupIds = _.flatMap(result, "root").map(rg => (rg._id ?? rg))

				let res = [
					...rootGroupIds.map(rgId => ({ type: "RootGroup", id: rgId })),
					...groupIds.map(gId => ({ type: "Group", id: gId })),
					...profileIds.map(pId => ({ type: "Profile", id: pId })),
					"RootGroup",
					"Group"
				]

				return res
			}
		}),
		deleteGroupMemberMulti: builder.mutation({
			query: ({ body }) => ({
				url: "/group/member",
				method: "DELETE",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				const profileIds = arg.body?.profileIds ?? []
				const groupIds = arg.body?.groupIds ?? []
				const rootGroupIds = _.flatMap(result, "root").map(rg => (rg._id ?? rg))

				let res = [
					...rootGroupIds.map(rgId => ({ type: "RootGroup", id: rgId })),
					...groupIds.map(gId => ({ type: "Group", id: gId })),
					...profileIds.map(pId => ({ type: "Profile", id: pId }))
				]

				return res
			}
		}),
		addGroupMember: builder.mutation({
			query: ({ groupId, rootId, body }) => ({
				url: `/group/${groupId}/member`,
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: arg.rootId }]
			}
		}),
		editGroupMember: builder.mutation({
			query: ({ groupId, profileId, body }) => ({
				url: `/group/${groupId}/member/${profileId}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: arg.groupId }]
			}
		}),
		deleteGroupMember: builder.mutation({
			query: ({ groupId, profileId, body }) => ({
				url: `/group/${groupId}/member/${profileId}`,
				method: "DELETE",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: arg.groupId }, { type: "Profile", id: arg.profileId }]
			}
		}),
		deleteGroupMembers: builder.mutation({
			query: ({ groupId, body }) => ({
				url: `/group/${groupId}/member`,
				method: "DELETE",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: result.root }, ...arg.body.profileIds.map(pId => ({ type: "Profile", id: pId }))]
			}
		}),
		addRootGroupPatron: builder.mutation({
			query: ({ id, body }) => ({
				url: `/root_group/${id}/patron`,
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }]
			}
		}),
		editRootGroupPatron: builder.mutation({
			query: ({ id, body }) => ({
				url: `/root_group/${id}/patron`,
				method: "PUT",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }]
			}
		}),
		deleteRootGroupPatron: builder.mutation({
			query: ({ id, body }) => ({
				url: `/root_group/${id}/patron`,
				method: "DELETE",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }]
			}
		}),
		addRootGroupMember: builder.mutation({
			query: ({ id, body }) => ({
				url: `/root_group/${id}/member`,
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }]
			}
		}),
		deleteRootGroupMember: builder.mutation({
			query: ({ id, profileId, body }) => ({
				url: `/root_group/${id}/member/${profileId}`,
				method: "DELETE",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }, { type: "Profile", id: arg.profileId}]
			}
		}),
		deleteRootGroupMembers: builder.mutation({
			query: ({ id, body }) => ({
				url: `/root_group/${id}/member`,
				method: "DELETE",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "RootGroup", id: arg.id }].concat(arg?.body?.profileIds?.map(pId => ({ type: "Profile", id: pId})) ?? [])
			}
		}),
		inviteToGroupMultiple: builder.mutation({
			query: ({ body, rootIds }) => ({
				url: "/group/invite",
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				const profileIds = arg.body?.profileIds ?? []
				const groupIds = arg.body?.groupIds ?? []
				const rootGroupIds = arg.rootIds ?? []

				return [
					...rootGroupIds.map(rgId => ({ type: "RootGroup", id: rgId })),
					...groupIds.map(gId => ({ type: "Group", id: gId })),
					...profileIds.map(pId => ({ type: "Profile", id: pId })),
					"RootGroup",
					"Group"
				]
			}
		}),
		// Body must include either email, sporttiId or palloid
		inviteToGroup: builder.mutation({
			query: ({ groupId, rootId, body }) => ({
				url: `/group/${groupId}/invite`,
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: arg.rootId }].concat(result.map(p => ({ type: "Profile", id: p._id})))
			}
		}),
		inviteToGroupWithId: builder.mutation({
			query: ({ groupId, profileId, body }) => ({
				url: `/group/${groupId}/invite/${profileId}`,
				method: "POST",
				body,
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: arg.groupId }, { type: "Profile", id: arg.profileId }]
			}
		}),
		cancelGroupInvite: builder.mutation({
			query: ({ groupId, rootId, inviteId, profile }) => ({
				url: `/group/${groupId}/cancelInvite/${inviteId}`,
				method: "POST",
			}),
			invalidatesTags: (result, error, arg) => {
				return _.compact([
					arg.rootId && { type: "RootGroup", id: arg.rootId },
					{ type: "Group", id: arg.groupId },
					{ type: "RootGroup", id: arg.groupId },
					arg.profile && { type: "Profile", id: arg.profile}
				])
			}
		}),
		cancelRootGroupInvite: builder.mutation({
			query: ({ rootId, groupId, inviteId, profile }) => ({
				url: `/root_group/${rootId}/cancelInvite/${inviteId}`,
				method: "POST",
			}),
			invalidatesTags: (result, error, arg) => {
				return _.compact([
					{ type: "RootGroup", id: arg.rootId },
					arg.groupId && { type: "Group", id: arg.groupId },
					arg.profile && { type: "Profile", id: arg.profile}
				])
			}
		}),
		acceptGroupInvite: builder.mutation({
			query: ({ groupId, bulletinId }) => ({
				url: `/group/${groupId}/acceptInvite/${bulletinId}`,
				method: "POST",
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: arg.groupId }, { type: "Profile", id: store.getState().user.profile }]
			}
		}),
		declineGroupInvite: builder.mutation({
			query: ({ groupId, bulletinId }) => ({
				url: `/group/${groupId}/declineInvite/${bulletinId}`,
				method: "POST",
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: arg.groupId }, { type: "Profile", id: store.getState().user.profile }]
			}
		}),
		cancelConfirmation: builder.mutation({
			query: ({ groupId, rootId, confirmationId }) => ({
				url: `/group/${groupId}/cancelConfirmation/${confirmationId}`,
				method: "PUT"
			}),
			invalidatesTags: (result, error, arg) => {
				return _.compact([
					arg.rootId && { type: "RootGroup", id: arg.rootId },
					{ type: "Group", id: arg.groupId },
					{ type: "RootGroup", id: arg.groupId }
				])
			}
		}),
		getGrowthStat: builder.query({
			query: (groupId) => `/statistics/growthStat/${groupId}`,
			providesTags: (result, error, arg) => {
				return ["TrainingResult", "Event"]
			}
		}),
		leaveAddedGroup: builder.mutation({
			query: ({ groupId, bulletinId, body }) => ({
				url: `/group/${groupId}/leaveAddedGroup/${bulletinId}`,
				method: "POST",
				body: body
			}),
			invalidatesTags: (result, error, arg) => {
				return [{ type: "Group", id: arg.groupId }, { type: "RootGroup", id: arg.groupId }, { type: "Profile", id: store.getState().user.profile }]
			}
		}),
		membershipRemovalRequest: builder.mutation({
			query: ({ groupId, groupRole }) => ({
				url: `/group/${groupId}/requestRemoval`,
				method: "POST",
				body: { groupRole }
			}),
			invalidatesTags: (result, error, arg) => {
				return ["Profile"]
			}
		}),
		membershipRemovalCancel: builder.mutation({
			query: ({ groupId, bulletinId }) => ({
				url: `/group/${groupId}/requestRemoval/${bulletinId}/cancel`,
				method: "POST",
			}),
			invalidatesTags: (result, error, arg) => {
				return ["Profile"]
			}
		}),
		membershipRemovalDecline: builder.mutation({
			query: ({ groupId, bulletinId }) => ({
				url: `/group/${groupId}/requestRemoval/${bulletinId}/decline`,
				method: "POST",
				responseHandler: (response) => {
					if (response.headers.get("content-type")?.includes("text")) {
						return response.text()
					}
					return response.json()
				}
			}),
			invalidatesTags: (result, error, arg) => {
				return ["Profile"]
			}
		}),
		membershipRemovalAccept: builder.mutation({
			query: ({ groupId, bulletinId }) => ({
				url: `/group/${groupId}/requestRemoval/${bulletinId}/accept`,
				method: "POST",
				responseHandler: (response) => {
					if (response.headers.get("content-type")?.includes("text")) {
						return response.text()
					}
					return response.json()
				}
			}),
			invalidatesTags: (result, error, arg) => {
				return ["Profile", { type: "Group", id: arg.groupId }]
			}
		}),
		setPassiveRootGroup: builder.mutation({
			query: ({ rootGroupId, groups }) => ({
				url: `/root_group/${rootGroupId}/setPassive`,
				method: "POST",
				body: { groups }
			}),
			invalidatesTags: (result, error, arg) => {
				return ["RootGroup", "Group", "Profile"]
			}
		}),
		unsetPassiveRootGroup: builder.mutation({
			query: ({ rootGroupId, groups }) => ({
				url: `/root_group/${rootGroupId}/unsetPassive`,
				method: "POST",
				body: { groups }
			}),
			invalidatesTags: (result, error, arg) => {
				return ["RootGroup", "Group", "Profile"]
			}
		}),
		setPassiveGroup: builder.mutation({
			query: ({ groupId }) => ({
				url: `/group/${groupId}/setPassive`,
				method: "POST"
			}),
			invalidatesTags: (result, error, arg) => {
				return ["Group", "Profile", "RootGroup"]
			}
		}),
		unsetPassiveGroup: builder.mutation({
			query: ({ groupId }) => ({
				url: `/group/${groupId}/unsetPassive`,
				method: "POST"
			}),
			invalidatesTags: (result, error, arg) => {
				let arr = ["Group", "Profile", "RootGroup"]
				return arr
			}
		}),
		resendEmail: builder.mutation({
			query: ({ rootGroupId, body, groupId }) => ({
				url: `/root_group/${rootGroupId}/resendemail`,
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, arg) => {
				let arr = [{ type: "RootGroup", id: arg.rootGroupId }]
				if(arg.groupId) {
					arr.push({ type: "Group", id: arg.groupId })
				}
				return arr
			}
		}),
	})
})

export const {
	useGetLocationsQuery,
	useGetGroupsQuery,
	useGetGroupHierarchyQuery,
	useGetMyGroupsQuery,
	useGetMyRootGroupsQuery,
	useGetRootGroupsQuery,
	useGetRootGroupsByProcessQuery,
	useCreateRootGroupMutation,
	useUpdateRootGroupMutation,
	useGetRootGroupClubSupportPlayersQuery,
	useUpdateRootGroupClubSupportPlayersMutation,
	useUnlockRootGroupClubSupportPlayersByFieldMutation,
	useDeleteRootGroupMutation,
	useGetRootGroupQuery,
	useGetSubGroupsQuery,
	useGetGroupByIdQuery,
	useAddLocationMutation,
	useRemoveLocationMutation,
	useCreateGroupMutation,
	useUpdateGroupMutation,
	useDeleteGroupMutation,
	useDeleteGroupWithoutInvalidationMutation,
	useAddGroupRoleMutation,
	useUpdateGroupRoleMutation,
	useDeleteGroupRoleMutation,
	useAddGroupMemberMultiMutation,
	useDeleteGroupMemberMultiMutation,
	useAddGroupMemberMutation,
	useEditGroupMemberMutation,
	useDeleteGroupMemberMutation,
	useDeleteGroupMembersMutation,
	useAddRootGroupPatronMutation,
	useEditRootGroupPatronMutation,
	useDeleteRootGroupPatronMutation,
	useAddRootGroupMemberMutation,
	useDeleteRootGroupMemberMutation,
	useDeleteRootGroupMembersMutation,
	useInviteToGroupMultipleMutation,
	useInviteToGroupMutation,
	useInviteToGroupWithIdMutation,
	useCancelGroupInviteMutation,
	useCancelRootGroupInviteMutation,
	useAcceptGroupInviteMutation,
	useDeclineGroupInviteMutation,
	useCancelConfirmationMutation,
	useSetDiaryVisibilityMutation,
	useGetGrowthStatQuery,
	useSearchGroupsQuery,
	useLazySearchGroupsQuery,
	useGroupsByRootQuery,
	useSearchRootGroupsQuery,
	useLazySearchRootGroupsQuery,
	useLeaveAddedGroupMutation,
	useMembershipRemovalRequestMutation,
	useMembershipRemovalCancelMutation,
	useMembershipRemovalAcceptMutation,
	useMembershipRemovalDeclineMutation,
	useUpdateRootGroup,
	useSetPassiveRootGroupMutation,
	useUnsetPassiveRootGroupMutation,
	useSetPassiveGroupMutation,
	useUnsetPassiveGroupMutation,
	useResendEmailMutation,
} = groupApi
