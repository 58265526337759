import { EVENT_TYPE_COLORS, EVENT_TYPE_COLOR_OPACITY, ROLE_ORDER, TZ } from "../const"

import i18n from "@/i18n"
import Banner from "@components/Common/Banner"
import { setContextData, setDictionary, setProductLevel, setProfileDataForCtx, setSelectedRole } from "@redux/user"
import { fromUnixTime, getUnixTime, intervalToDuration, parseISO, startOfDay } from "date-fns"
import { formatInTimeZone } from "@/overrides"
import { saveAs } from "file-saver"
import _, { isArray } from "lodash"
import { Alert } from "react-bootstrap"
import { Check, Dash, X } from "react-bootstrap-icons"
import { Menu, MenuItem } from "react-bootstrap-typeahead"
import { Trans } from "react-i18next"
import store from "../store"
import toast from "react-hot-toast"
import { serialize } from "object-to-formdata"

const t = i18n.t

export const isNotEmpty = _.negate(_.isEmpty)

export const delay = ms => new Promise(res => setTimeout(res, ms))

export const getDurationString = (startDate, endDate) => {
	const duration = intervalToDuration({
		start: startDate,
		end: endDate
	})
	return durationToString(duration)
}

/**
 * @param {string | number | Date} date
 * @param {string} timeZone
 * @param {string} formatStr
 * @param {import("date-fns-tz").OptionsWithTZ?} options
 */
export const formatInFinnishTZ = (date, timeZone, formatStr) => {
	return formatInTimeZone.call()
}

export const durationToString = duration => {
	let durationArray = []
	if (duration.years > 0) durationArray.push(duration.years + "y")
	if (duration.months > 0) durationArray.push(duration.months + "kk")
	if (duration.days > 0) durationArray.push(duration.days + "pv")
	if (duration.hours > 0) durationArray.push(duration.hours + "t")
	if (duration.minutes > 0) durationArray.push(duration.minutes + "m")
	return durationArray.join(" ")
}

// Get default time hours and minutes for survey or other repeating tasks
export const getDefaultTaskTimes = (eventStart) => {
	let time = new Date(eventStart)
	time.setHours(0)
	time.setMinutes(0)
	time.setSeconds(0)
	const defaultStart = new Date(time)
	time.setHours(23)
	time.setMinutes(59)
	time.setSeconds(59)
	const defaultEnd = new Date(time)
	return [defaultStart, defaultEnd]
}

// Checks if current time is after task `closesAt` value
export const isAfterSurveyClose = (event) => {
	if (!event)
		return false

	let now = new Date()
	let closesAt = parseISO(event.endTime)

	return now > closesAt
}

// Used for getting age from birthdate
export const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

// Used for downloading personal data as a JSON file
export const downloadProfile = (profileData, userData) => {
	if (!profileData || !userData) return
	let listedUserData = {
		Firstname: profileData?.firstname,
		Lastname: profileData?.lastname,
		Username: userData?.username,
		Email: userData?.email,
		Eenet_email: userData?.eenet_email,
		Eenetid: userData?.eenetid ?? profileData?.eenetid,
		Phone: profileData?.phone,
		Address: profileData?.address,
		AddressZip: profileData?.addressZip,
		AddressLocality: profileData?.addressLocality,
		Birthday: profileData?.birthday && formatInTimeZone(new Date(profileData?.birthday), TZ, "dd/MM/yyyy"),
		Foot: profileData?.foot,
		Age: profileData?.age && Math.floor(profileData?.age),
		PalloID: profileData?.palloid,
		SporttiID: profileData?.sporttiId,
		Gender: profileData?.sex,
		Injury: profileData?.injury,
	}
	if (profileData?.parents.length > 0) listedUserData.Parents = profileData?.parents.map(parent => parent?.name)
	if (profileData?.children.length > 0) listedUserData.Children = profileData?.children.map(child => child?.name)
	if (profileData?.watches.length > 0) listedUserData.Watches = profileData?.watches.map(watch => watch?.name)
	listedUserData = Object.fromEntries(Object.entries(listedUserData).filter(([_, v]) => v !== ""))
	listedUserData = JSON.stringify(listedUserData, null)
	var blob = new Blob([listedUserData], { type: "text/plain;charset=utf-8" })
	saveAs(blob, `${profileData?.firstname} ${profileData?.lastname}.json`)
}

// Only include roles that should be selectable
export const roleFilter = (roles) =>
	roles.filter((role) => ["basic_user", "admin", "patron", "club_support_admin"].includes(role))

export const productLevelFilter = (roles) =>
	roles.filter((role) => ["prod1", "prod2", "prod3", "prod4", "prod5"].includes(role))

/**
 * Convenience function for sorting values by their order of appearance in a reference array.
 * 
 * Matching is case insensitive. Not found values are pushed to the end of the sorted list.
 * 
 * @param {[String | Object]} targetArr       The array to be sorted. Can contain strings or objects; the latter will need path argument specified.
 * @param {[String]} referenceArr             The reference array that sorting is based on.
 * @param {String} path                       If the array to be sorted contains object, path is used for getting the associated string value
 * @returns {[String | Object]}               Sorted `targetArr` array
 */
export const sortByArray = (targetArr, referenceArr, path) => {
	return targetArr.sort((a, b) => {
		let valA = path ? _.get(a, path) : a
		let valB = path ? _.get(b, path) : b
		if (_.isObject(valA))
			valA = valA.name
		if (_.isObject(valB))
			valB = valB.name
		if (!_.isString(valA) || !_.isString(valB))
			return 0
		let indexA = referenceArr.findIndex(str => str.toLowerCase() === valA.toLowerCase())
		let indexB = referenceArr.findIndex(str => str.toLowerCase() === valB.toLowerCase())
		if (indexA === -1 && indexB === -1)
			return 0
		if (indexA === -1)
			return 1
		if (indexB === -1)
			return -1
		return indexA - indexB
	})
}

// Sorts group roles in preset order
export const sortGroupRoles = (roles) => {
	if (!roles || !Array.isArray(roles))
		return []
	let copy = [...roles]
	const reference = ["headcoach", "coach", "player", "manager", "Valmennuspäällikkö", "Valmentaja", "Pelaaja", "Joukkueenjohtaja"]
	if (copy.every(o => _.isObject(o))) {
		return sortByArray(copy, reference, "name")
	} else {
		return sortByArray(copy, reference)
	}
}

// Sorts roles in descending order
export const sortRoles = (roles) => {
	if (!roles || !Array.isArray(roles))
		return []
	let copy = [...roles]
	const reference = ["admin", "basic_user", "prod5", "prod4", "prod3", "prod2", "prod1"]
	return sortByArray(copy, reference)
}

export const splitFilter =
	(str, getField = (obj) => obj.name, op = "every") =>
		(obj) => {
			if (!str) return true
			const value = getField(obj).toLowerCase()
			return str
				.toLowerCase()
				.split(" ")
				[op]((term) => value.includes(term))
		}

export const playerNameFilter = (str) => (player) => {
	if (!str) return true
	const lcFirstname = player.profile.firstname.toLowerCase()
	const lcLastname = player.profile.lastname.toLowerCase()
	return str
		.toLowerCase()
		.split(" ")
		.every((term) => lcFirstname.indexOf(term) === 0 || lcLastname.indexOf(term) === 0)
}

export const getEventColor = (eventType) => {
	if (eventType?.color) {
		return eventType.color
	}

	if (EVENT_TYPE_COLORS.hasOwnProperty(eventType)) return EVENT_TYPE_COLORS[eventType] + EVENT_TYPE_COLOR_OPACITY
	else return EVENT_TYPE_COLORS["default"] + EVENT_TYPE_COLOR_OPACITY
}

/*
	Temporary solution based on current event types. 
	TODO: Replace with a more robust solution.
*/
export const isGroupEvent = (event) => (!event.isPersonal)
export const isPersonalEvent = (event) => (event.isPersonal)
export const isEventCreator = (user, event) => event?.creator?._id === user.profile || event?.creator === user.profile
export const isEventOfficial = (user, event) => {
	if (!event.officials)
		return false
	return event.officials.some(off => off.profile?._id === user.profile || off.profile === user.profile)
}

export const dragAndDropIsSupported = () => {
	const div = document.createElement("div")
	const enabled = "draggable" in div || ("ondragstart" in div && "ondrop" in div)
	div.remove()
	return enabled
}

/**
 * Converts what would conventionally be JSON body
 * data to equivalent FormData object.
 * 
 * Backend body-parser 'extended' option should be set to true
 * to allow array and object parsing of form data.
 * 
 * @param {Object} values       Object that contains form data as field-value pairs
 * @returns {FormData}          FormData result
 */
export const convertObjToFormData = (values, stringifyEmpty) => {
	const formData = serialize(values, { indices: true, allowEmptyArrays: true })

	// Iterate form data entries and move file entries to the end; required by backend streaming file upload implementation
	let newFormData = new FormData()
	let files = []
	for (let [fieldname, value] of formData.entries()) {
		if (value instanceof File) {
			let file = formData.get(fieldname)
			files.push([fieldname, file])
		} else {
			newFormData.append(fieldname, value)
		}
	}
	for (let [fieldname, file] of files) {
		newFormData.append(fieldname, file)
	}

	return newFormData
}

export const convertObjToQueryStr = (obj) => {
	if (!obj || _.isEmpty(obj)) return ""
	let str = "?"
	Object.keys(obj).forEach((key) => {
		if (Array.isArray(obj[key])) {
			obj[key].forEach((val) => {
				val = encodeURIComponent(val)
				str = str.concat(`${key}=${val}&`)
			})
		} else {
			let val = obj[key]
			if (val != null && val !== "") {
				val = encodeURIComponent(val)
				str = str.concat(`${key}=${val}&`)
			}
		}
	})
	// Remove trailing & if present
	if (str[str.length - 1] === "&")
		str = str.slice(0, str.length - 1)
	return str
}

// Number array functions (mainly for aggregating multiple samples)
export const median = (arr) => {
	const sorted = arr.slice().sort((a, b) => a - b)
	const middle = Math.floor(sorted.length / 2)

	if (sorted.length % 2 === 0) {
		return (sorted[middle - 1] + sorted[middle]) / 2
	}
	return sorted[middle]
}

export const average = (arr) => {
	if (Array.isArray(arr) && arr.length > 0) return arr.reduce((prev, next) => prev + next, 0) / arr.length
	else throw new Error("Provided argument is not an array or has 0 items")
}

export const max = (arr) => Math.max(...arr)

export const min = (arr) => Math.min(...arr)

/**
 * Returns a new array of result definitions using the Training 'samples' attribute.
 *
 * Also adds various control attributes to returned result objects (sampleNum, useLabel, suffix).
 *
 * @param {Object} training             Training object that has 'samples' attribute defined depending on context, ie.
 *                                      Training defaultSample or TestProtocol testOrder[i].samples
 * @param {Boolean} includeSpecials     Concatenates training special fields to returned array if true.
 * @param {Boolean} mangleDuplicates    Adds suffix or useLabel attribute to returned results to aid in generating unique labels.
 * @param {Boolean} longHeader          Uses full presentation for test result definition header to avoid collision in case of multiple tests 
 *
 * @returns {[Object]}                  Array of result definitions, individually repeated according to sample. Items have
 *                                      'sampleNum' attribute attached to signify nth sample.
 */
export const getSampledResultDefs = (training, includeSpecials, mangleDuplicates = true, longHeader) => {
	let testSamples = 1
	if (training.samples)
		testSamples = training.samples
	let resultArr = training.result
	let resultDefs = resultArr
		.map((res, i) => {
			// If a sample number is defined both on test and individual result definition, they are multiplied
			let usedSample = 1
			let defSamples = res.samples
			if (defSamples) {
				usedSample = defSamples * (Array.isArray(testSamples) ? testSamples[i] : testSamples)
			}
			else {
				usedSample = testSamples
			}

			return [...Array(usedSample)].map((_, j) => ({
				...res,
				sampleNum: usedSample > 1 ? j + 1 : undefined,
				testName: training.name,
			}))
		})
		.reduce((a, b) => a.concat(b), []) // Flatten resulting nested array structure

	// Additional processing
	if (includeSpecials) resultDefs = resultDefs.concat(training.specialfields)
	if (longHeader)
		return resultDefs.map(o => ({ ...o, useLabel: true, suffix: ` [${o.testName}]` }))
	if (mangleDuplicates) {
		const matchKeys = ["unit", "sampleNum"]
		const comparator = (keys) => (a, b) => keys.every((key) => a[key] === b[key])
		let unique = _.uniqWith(resultDefs, comparator(matchKeys))
		if (unique.length !== resultDefs.length) { // Units are repeated and will not be used as test identifier
			const labelKeys = ["label", "sampleNum"]
			let labelUniq = _.uniqWith(resultDefs, comparator(labelKeys))
			if (labelUniq.length === resultDefs.length) {
				resultDefs = resultDefs.map((o) => ({ ...o, useLabel: true }))
			} else {
				// Final fallback is to add index suffix
				resultDefs = resultDefs.map((o, i) => ({ ...o, suffix: `_${i + 1}` }))
			}
		}
	}
	return resultDefs
}

/**
 * Returns an iterable of all identity combinations for round, result
 * definition and sample indexes. Use spread operator to convert it to
 * array form.
 * 
 * @param {Object} test           Training document; used for checking number of result definitions and their samples
 * @param {Number} rounds         Number of rounds; usually derived from protocol attached to test instance
 * @returns {Iterable}            Iterable of all identity combinations.
 */
export const getResultIndexIterable = (test, rounds = 1) => {

	let combinations = []
	for (let i = 0; i < rounds; i++) {
		for (let [j, resultDef] of test.result.entries()) {
			let samples = resultDef.samples
			for (let k = 0; k < samples; k++) {
				combinations.push([i, j, k])
			}
		}
	}

	// Create iterable
	const iter = {}
	iter[Symbol.iterator] = function () {
		let done = false
		let n = 0
		let arrs = combinations

		return {
			next() {
				let arr = arrs[n]
				if (!arr) {
					done = true
				}
				n += 1
				return { value: arr, done }
			}
		}
	}

	return iter
}

// Returns the length of result index iterable
export const getResultCount = (test, rounds = 1) => {
	return [...getResultIndexIterable(test, rounds)].length
}

// Returns the index place of a particular identity in iterable
export const getIdentityIndexPlace = (test, rounds = 1, identity) => {
	return [...getResultIndexIterable(test, rounds)].findIndex(ident => _.isEqual(identity, ident))
}


/**
 * Returns the result index iterable as a traversable object.
 * 
 * @param {Object} test           Training document; used for checking number of result definitions and their samples
 * @param {Number} rounds         Number of rounds; usually derived from protocol attached to test instance
 * @returns {Object}              Nested object with keys corresponding to round, result def index, sample
 */
export const getTraversableIndexObject = (test, rounds = 1) => {
	const identities = [...getResultIndexIterable(test, rounds)]

	const obj = {}

	const roundGrouped = _.groupBy(identities, v => v[0])
	for (let roundDx in roundGrouped) {
		let arr = roundGrouped[roundDx]
		const defGrouped = _.groupBy(arr, v => v[1])
		for (let defDx in defGrouped) {
			let arr2 = defGrouped[defDx]
			const sampleGrouped = _.groupBy(arr2, v => v[2])
			_.set(obj, [roundDx, defDx], sampleGrouped)
		}
	}
	return obj
}

/**
 * Returns all nested keys in an object via recursion. Recursion stops when a non-object
 * leaf value is encountered.
 * 
 * @param {Object} obj      Object whose nested keys are retrieved
 * @param {Array} arr       Starting array used in recursion
 * @returns {[String]}      Array of nested object keys
 */
export const getAllNestedKeys = (obj, arr = []) => {
	if (!_.isObject(obj))
		return
	let entries = Object.entries(obj)
	for (let entry of entries) {
		let key = entry[0]
		let val = entry[1]
		arr.push(key)
		getAllNestedKeys(val, arr)
	}
	return arr
}

/**
 * Returns all nested values in an object via recursion. Recursion stops when non-object
 * primitive is encountered.
 * 
 * @param {Object} obj      Object whose nested keys are retrieved
 * @param {Array} arr       Starting array used in recursion
 * @returns {[String]}      Array of nested object keys
 */
export const getAllNestedValues = (obj, arr = []) => {
	if (!_.isObject(obj)) {
		arr.push(obj)
		return
	}
	let entries = Object.entries(obj)
	for (let entry of entries) {
		let val = entry[1]
		getAllNestedValues(val, arr)
	}
	return arr
}

export const checkIdRequired = (profileData, sportNames) => {
	if (!profileData?.groupRoleOverview || !sportNames)
		return false

	const hasSport = profileData?.opClassList?.some(str => sportNames.includes(str))
	const idRequired = profileData?.combinedGeneralPerms?.includes("palloIdRequired")
	return hasSport && idRequired
}

/**
 * Utility function for getting a default initial invite row or an additional row that copies some of the first invitations's values.
 * 
 * Used in InviteTableInput and components embedding it.
 */
export const getDefaultInvite = (invites, initialGroup = "", initialGroupRole = "") => {

	const getNewRowId = () => {
		let rowId = new Date().getTime()
		const rowIds = invites.map(invite => invite.rowId)
		while (rowIds.includes(rowId)) rowId++
		return rowId

	}

	return {
		email: "",
		firstname: "",
		lastname: "",
		group: invites.length ? invites[0]?.group : initialGroup,
		groupRole: invites.length ? invites[0]?.groupRole : initialGroupRole,
		rowId: getNewRowId(),
		child: invites.length ? invites[0]?.child : (initialGroupRole === "player" ? true : false)
	}
}

export const getInviteTitle = (type, data) => {
	let text = ""
	let { lastname, firstname, groupName, childName } = data

	if (!firstname && !lastname) {
		({ firstname, lastname } = (data.referenceDoc ?? {}))
	}

	switch (type) {
	case "invite":
		text = t("Hyväksy kutsu ryhmään {{groupName}}.", { lastname, firstname, groupName })
		break
	case "invite-child-when-non-parent":
	case "add_new_parent":
		text = t("Hyväksy huoltajuus huollettavalle '{{lastname}} {{firstname}}'.", { lastname, firstname, groupName })
		break
	case "invite-own-child":
		text = t("Hyväksy huollettavan '{{lastname}} {{firstname}}' kutsu ryhmään {{groupName}}", { lastname, firstname, groupName })
		break
	case "invite-other-child":
		text = t("Hyväksy huoltajuus huollettavalle '{{lastname}} {{firstname}}'.", { lastname, firstname, groupName })
		break
	case "invite-register-other-child":
		text = t("Hyväksy huoltajuus ja rekisteröi huollettava '{{lastname}} {{firstname}}'.", { lastname, firstname, groupName })
		break
	case "invite-register-child-when-non-parent":
		text = t("Hyväksy huoltajuus ja rekisteröi huollettava '{{lastname}} {{firstname}}'.", { lastname, firstname, groupName })
		break
	case "activate-child":
		text = t("Hyväksy huollettavan '{{lastname}} {{firstname}}' huoltajuus.", { lastname, firstname, groupName })
		break
	case "confirm-invite":
		text = t("Sinut on lisätty ryhmään {{groupName}}. Voit hyväksyä tämän tai hylätä, jolloin sinut poistetaan automaattisesti ryhmästä", { groupName })
		break
	case "confirm-invite-child":
		text = t("Sinun huollettava '{{lastname}} {{firstname}}' on lisätty uuteen ryhmään {{groupName}}. Voit hyväksyä tämän tai hylätä, jolloin hänet poistetaan automaattisesti ryhmästä", { lastname, firstname, groupName })
		break
	default:
		text = t("Hyväksy kutsu ryhmään {{groupName}}.", { lastname, firstname, groupName })
	}
	return text
}

export const generateUsername = (firstname, lastname, confirmation) => {
	let num = Math.random().toString().slice(2, 6).padEnd(4, "0")

	if (firstname && lastname) {
		let namePart = `${firstname}${lastname}`.slice(0, 20)
		return `${namePart}${num}`
	}
	if (confirmation) {
		let emailPart = confirmation.email?.split("@")?.[0]?.slice(0, 7) ?? "käyttäjä"
		return `${emailPart}${num}`
	}
	return `käyttäjä${num}`
}

export const getBanners = (profileData, navigate) => {
	if (!profileData)
		return null
	return profileData?.pendingChildAccounts?.map((child, i) => {
		let { firstname, lastname, accountType } = child
		if (accountType === "register") {
			return (
				<Banner key={i}>
					<div className="text-center">
						<Trans t={t}>
							Sinun tulee <Alert.Link
								onClick={() => {
									navigate("/registerchild", { state: { ...child, pendingChildAccounts: profileData?.pendingChildAccounts } })
								}}>
								rekisteröidä
							</Alert.Link> huollettava <strong>{{ firstname }} {{ lastname }}</strong> käyttäjäksi!
						</Trans>
					</div>
				</Banner>
			)
		} else if (accountType === "activate") {
			return (
				<Banner key={i}>
					<Trans t={t}>
						Sinun pitää <Alert.Link
							onClick={() => {
								navigate("/activatechild", { state: { ...child, pendingChildAccounts: profileData?.pendingChildAccounts } })
							}}>
							aktivoida
						</Alert.Link> huollettava <strong>{{ firstname }} {{ lastname }}</strong> käyttäjäksi!
					</Trans>
				</Banner>
			)
		} else {
			return (
				<Banner key={i}>
					<Trans t={t}>
						Sinun täytyy <Alert.Link
							onClick={() => {
								navigate("/child_invite_confirm", { state: { ...child, pendingChildAccounts: profileData?.pendingChildAccounts } })
							}}>
							hyväksyä
						</Alert.Link> huollettava <strong>{{ firstname }} {{ lastname }}</strong> uuteen ryhmään!
					</Trans>
				</Banner>
			)
		}
	})
}

/** 
 * Takes in `unixTime`, floors it to start of day and returns it back as unix time. 
 * @param {number} unixTime
 * 
*/
export const floorToUnixDay = (unixTime) => {
	return getUnixTime(startOfDay(fromUnixTime(unixTime)))
}

/** 
 * Variant of `floorToUnixDay` allowing immutably to convert one field from object to floored unix time.  
 * @param {object} obj
 * @param {string} key
*/
export const floorToUnixDayOnObject = (obj, key) => {
	return { ...obj, [key]: floorToUnixDay(obj[key]) }
}

/**
 * Gets the referenced tests in protocol or single test.
 * 
 * @param {Object} item             Training object. Should have defined `result` path.
 * @param {[Object]} testData       List of test data and their definitions.
 * @returns                         Empty string or comma-separated list of test names.
 */
export const getReferencedTests = (item, testData) => {
	if (!testData)
		return ""
	let referencedTests = []
	let arr = []
	// Item is a protocol
	if (item.testOrder) {
		arr = item.tests
		// Item is a test
	} else {
		arr = [item]
	}
	for (let test of arr) {
		let fullTest = testData?.find(t => t?._id === test?._id)
		if (!fullTest)
			continue
		let refTests = fullTest.result.reduce((a, b) => a.concat(b.referencedTest), [])
		refTests = _.compact(refTests)
		referencedTests = referencedTests.concat(refTests)
	}
	let fullReferenceTests = referencedTests.map(id => testData?.find(test => id === test._id))
	return fullReferenceTests.map(test => test?.name).join(", ")
}

/**
 * Returns boolean on whether an aggregate should be displayed for a training result.
 * 
 * Aggregates like average, maximum, minimum etc. often do not make
 * sense when they have multiple result definitions with different units.
 * 
 * @param {Object} resultDefinitions            Object that contains result definitions
 * @returns {Boolean}                           Whether an aggregate is applicable
 */
export const noAggDisplay = (resultDefinitions = []) => {
	return _.uniqBy(resultDefinitions, "unit").length > 1
}

/**
 * Returns the tree of possible rootGroup - group - groupRole combinations from profile data.
 * The tree terminates in group role name array. Also return id and role name keyed map of document information.
 *
 * @param {Object} profileData                  Own profile response
 * @param {Boolean} omitGroup                   Leaves out group level in returned tree and doc map; group roles are combined
 * 
 * @returns {Array[0]}                          Tree of rootGroup - group - group role name combinations. Terminates in group role name array. 
 * @returns {Array[1]}                          Object keyed by document id or group role name. Its values are the document's data.
 */
export const getContextTreeAndDocs = (profileData, omitGroup) => {
	if (!profileData) {
		return [{}, {}]
	}

	const tree = {}
	const docMap = {}

	// Derived from being in `rootGroup.patrons` path, and their groups in populated `rootGroup.groups`
	const patronRootGroups = profileData.patronRootGroups ?? []
	const patronGroups = _.flatMap(patronRootGroups, "groups")

	// Derived from being in `group.members` path
	const rootGroups = profileData.rootGroups ?? []
	const groups = profileData.groups ?? []

	const combinedRootGroups = _.uniqBy(patronRootGroups.map(rg => ({ ...rg, viaPatron: true })).concat(rootGroups), "_id")
	const combinedGroups = _.uniqBy(patronGroups.map(g => ({ ...g, viaPatron: true })).concat(groups), "_id")

	for (let rg of combinedRootGroups) {
		_.set(docMap, [rg._id], rg)
		_.set(tree, [rg._id], null) // Initialize a value in case of profile with no group memberships
		const groupsWithRg = combinedGroups.filter(g => (g.root?._id ?? g.root) === rg._id)
		let arrRef
		if (omitGroup) {
			_.set(tree, [rg._id], [])
			arrRef = _.get(tree, [rg._id])
		}

		for (let g of groupsWithRg) {
			_.set(docMap, [g._id], g)
			if (!omitGroup) {
				_.set(tree, [rg._id, g._id], [])
				arrRef = _.get(tree, [rg._id, g._id])
			}
			const ownMems = g.members.filter(m => m.profile === profileData._id)
			for (let m of ownMems) {
				for (let gr of m.hasGroupRoles) {
					if (_.isString(gr)) { // Retrieve template role definition if not already populated
						gr = rg.templateRoles.find(tr => tr.name === gr)
						if (!gr) {
							continue // Skip saving group role if not available
						}
					}

					gr = _.clone(gr)
					const inGroups = groupsWithRg.reduce((a, b) => {
						const g = b
						const hasRole = g.members.find(m => m.profile === profileData._id)?.hasGroupRoles.some(_gr => (_gr?.name ?? _gr) === gr?.name)
						if (hasRole && !a.includes(g._id)) {
							return a.concat(g._id)
						}
						return a
					}, [])

					gr.root = rg._id
					gr.inGroups = inGroups

					// Also save value for root group specific role instance
					_.set(docMap, [`${gr.name}_${rg._id}`], gr)
					if (!_.get(docMap, [gr.name])) {
						_.set(docMap, [gr.name], gr)
					}

					if (!arrRef.includes(gr.name)) {
						arrRef.push(gr.name)
					}
				}
			}
			if (rg.viaPatron) {
				arrRef.unshift("patron")
			}
		}
		if (rg.viaPatron) {
			const patronDoc = {
				name: "patron",
				title: "Pääkäyttäjä",
				generalPerms: [],
				root: rg._id,
				inGroups: groupsWithRg.map(g => g._id),
				rolePermMap: {},
				itemPerms: []
			}
			_.set(docMap, ["patron"], patronDoc)
			_.set(docMap, [`patron_${rg._id}`], patronDoc)
			if (_.get(tree, [rg._id]) === null) { // Root group branch has zero groups
				if (!omitGroup) {
					_.set(tree, [rg._id, "patronPlaceholderGroup"], ["patron"])
					// Filter this group out as necessary
					_.set(docMap, ["patronPlaceholderGroup"], { name: "Patron placeholder group" })
				} else {
					_.set(tree, [rg._id], ["patron"])
				}
			}
		}
	}

	return [tree, docMap]
}

/**
 * Performs a check on the input value dependent on current root group context
 * and whether user is currently admin.
 * 
 * @param {String} rootGroupId      Root Group Id that context would be checked against
 * @returns {Boolean}               Whether value is matched with context 
 */
export const rootCtxBool = (rootGroupId) => {
	const contextData = store.getState().user?.contextData
	const isAdmin = store.getState().user?.selectedRole === "admin"
	return isAdmin || contextData?.rootGroup === rootGroupId
}

export const secsToTimeString = (seconds) => {
	if (seconds === 0)
		return "00:00:00"

	const h = Math.floor(seconds / 3600).toString()
	const m = Math.floor(seconds % 3600 / 60).toString()
	const s = Math.floor(seconds % 3600 % 60).toString()

	const hStr = h.length === 1 ? "0" + h : h
	const mStr = m.length === 1 ? "0" + m : m
	const sStr = s.length === 1 ? "0" + s : s

	return `${hStr}:${mStr}:${sStr}`
}

export const secsFromTimeString = (str) => {
	const [h, m, s] = str.split(":").map(v => _.parseInt(v))
	const total = h * 3600 + m * 60 + s
	if (isNaN(total))
		return 0
	return total
}

/**
 * Sets context state values based on input profile data. Context is used in various permission checks and conditional rendering.
 * 
 * Validity of profile context values is handled in the backend during own profile's GET query.
 * 
 * @param {Object} data 					Profile data for which context is set to.
 * @param {Function} dispatch 		Dispatch function from component useDispatch() hook
 * @returns												Context object of rootGroup, group and groupRole
 */
export const initializeCtx = (data, dispatch) => {
	let obj = data?.settings?.context
	if (!obj)
		return { rootGroup: "", group: "", groupRole: "", adminToggle: false }
	let { rootGroup, group, groupRole, adminToggle } = obj
	dispatch(setContextData({ rootGroup, group, groupRole, adminToggle }))
	dispatch(setProfileDataForCtx(data))

	const adminAvailable = !!data?.dnInfo?.isAdmin
	if (adminToggle && adminAvailable) {
		dispatch(setSelectedRole("admin"))
	} else {
		dispatch(setSelectedRole("basic_user"))
	}

	const curRoot = data.rootGroups.find(rg => rg._id === rootGroup)
	if (curRoot) {
		dispatch(setProductLevel(curRoot.productLevel))
	}
	if (adminToggle) {
		dispatch(setProductLevel(5))
	}

	const returnVal = { rootGroup, group, groupRole, adminToggle }
	return returnVal
}

/**
 * Perform query to retrieve all available root group class derived
 * name-title mappings, keyed by root group class.
 * 
 * The dictionary is a somewhat static object that is used for determining
 * various event type and component event type displayed readable text.
 * 
 * @param {Object} queryTrigger 	Lazy query trigger for retrieving dictionary data
 * @param {Function} dispatch 		Dispatch function from component useDispatch() hook
 * @returns												RGC id keyed object of name-title pairs.
 */
export const initializeDictionary = (queryTrigger, dispatch) => {
	queryTrigger().unwrap().then(r => dispatch(setDictionary(r.all))).catch(e => console.error(e))
}

/**
 * Handle various path configurations for participants / profile lists and return profile name and role text
 * 
 * @param {Object} option 										Object that contains some path to profile-attached groups
 * @param {[String]} groupRoleGroupIds 				Array of group ids that all groups of profile are filtered agains
 * @returns {[String, String]}								Profile name and comma-separated role titles
 */
export const getProfileNameAndRole = (profile, groupRoleGroupIds, shortenGroups) => {
	let roleText = t("Ei ryhmärooleja")
	const profileRef = profile.profile ?? profile
	const profileName = profileRef?.name ?? `${profileRef.lastname} ${profileRef.firstname}`
	const overview = profileRef?.groupRoleOverview
	const rootCtx = store.getState().user?.contextData?.rootGroup

	if (!overview)
		return [profileName, roleText, null]

	if (groupRoleGroupIds) {
		groupRoleGroupIds = groupRoleGroupIds.map(g => g._id ?? g)
	}

	let filteredOverview = rootCtx ? _.pick(overview, rootCtx) : overview
	let allRoots = _.values(filteredOverview).filter(o => _.isObject(o))
	let allGroups = allRoots.reduce((a, b) => {
		let groups = b.groups.map(gId => ({ ...b[gId], id: gId }))
		return a.concat(...groups)
	}, [])
	let filteredGroups = allGroups.filter(g => groupRoleGroupIds?.length > 0 ? groupRoleGroupIds?.includes(g.id) : true)
	let filteredTitles = _.uniq(_.flatMap(filteredGroups, "memberRoles").map(r => r.title))
	roleText = filteredTitles
	roleText = roleText.join(", ")
	let groups = filteredGroups.map(g => g.name).join(", ")

	if (filteredGroups.length > 2 && shortenGroups) {
		groups = t("Useita ryhmiä")
	}
	return [profileName, roleText, groups]
}

// Use in 'onKeyDown' handler in inputs to prevent typing of non-positive non-integers
export const blockNotPosIntegers = e => ["e", "E", "+", "-", ",", "."].includes(e.key) && e.preventDefault()

// Use in 'onKeyDown' handler to go down one input when pressing enter key in test result input page
export const handleEnter = (event, resultDef, trainee, sampleDx, trainees, tests, roundDx, testId, resultDefDx) => {
	if (event.keyCode === 13) { // Enter key has been pressed on a text input so we navigate down
		const form = event.target.form
		const inputs = form.querySelectorAll("input[type='number']") // Get all inputs with the type number in form (this includes ALL inputs, event the ones that are in different tabs)
		const currentFocusIndex = Array.from(inputs).indexOf(event.target) // Currently focused input in the inputs
		const traineeIndex = trainees.findIndex((person) => person?.profile?._id === trainee?.profile?._id)
		let currentTest = isArray(tests) ? tests.find((te) => te._id === testId) : tests
		let textInputsPerRow = 0
		let rounds = 0
		if (isArray(tests)) {
			for (let i = 0; i < tests.length; i++) {
				rounds = tests[i]?.rounds ? tests[i]?.rounds : 1
				textInputsPerRow += _.sumBy(tests[i]?.result, "samples") * rounds
			}
		} else {
			rounds = tests?.rounds ? tests?.rounds : 1
			textInputsPerRow = _.sumBy(tests?.result, (result) => _.get(result, "samples", 0)) * rounds
		}
		const textInputsInOneSlot = currentTest?.result[resultDefDx]?.samples ? currentTest?.result[resultDefDx]?.samples * rounds ?? 1 : currentTest?.rounds
		if (textInputsInOneSlot > (sampleDx + 1) * (roundDx + 1) || (textInputsInOneSlot === (sampleDx + 1) * (roundDx + 1) && currentTest?.result?.length === 1 && traineeIndex < trainees.length - 1) && !isArray(tests)) {
			inputs[currentFocusIndex + 1].focus()
		} else if (traineeIndex < trainees.length - 1 && (textInputsInOneSlot === (sampleDx + 1) * (roundDx + 1))) {
			inputs[currentFocusIndex + textInputsPerRow - textInputsInOneSlot + 1].focus()
		} else if (traineeIndex === trainees.length - 1) {
			inputs[currentFocusIndex - textInputsPerRow * (trainees.length - 1) - textInputsInOneSlot + 1].focus()
		}

		event.preventDefault()
	}
}

/**
 * Get object array of root groups using `profile.groupRoleOverview` object.
 */
export const getOverviewRootGroups = groupRoleOverview => {
	let groups = _.entries(groupRoleOverview).map(([k, v]) => ({ name: v?.name, _id: k }))
	groups = _.compact(groups)
	return groups
}

/**
 * Get object array of groups using `profile.groupRoleOverview` object.
 */
export const getOverviewGroups = groupRoleOverview => {
	let groupArrs = _.entries(groupRoleOverview).map(([k, v]) => v.groups.map(gId => {
		let gObj = _.get(v, gId)
		return { _id: gId, name: gObj?.name }
	}))
	let result = _.compact(_.flatten(groupArrs))
	return result
}

export const getGroupedRenderMenu = ({ groupByPath, hasNextPage, labelKey }) => (results, menuProps) => {
	if (!groupByPath) {
		return null
	}

	if (!labelKey) {
		labelKey = "name"
	}

	// Remove menu props that are not compatible with 'div' DOM element.
	const renderMenuItemChildren = _.get(menuProps, "renderMenuItemChildren")
	const passedProps = _.omit({ ...menuProps }, ["renderMenuItemChildren", "paginationText", "newSelectionPrefix"])

	// Create a flat array instead of rendering a nested loop to avoid render update max depth error
	const grouped = _.groupBy(results, groupByPath)

	const flatArr = []
	const sortedEntries = _.entries(grouped).sort((a, b) => {
		const ak = a[0]
		const bk = b[0]
		let keyAsc = ak.localeCompare(bk)
		return keyAsc
	})

	for (let [k, v] of sortedEntries) {
		let arr = []
		const sortedVal = v.sort((a, b) => _.isFunction(labelKey) ? labelKey(a)?.localeCompare(labelKey(b)) : _.get(a, labelKey)?.localeCompare(_.get(b, labelKey)))
		if (k === "undefined") {
			arr = [...sortedVal]
		} else {
			arr = [k, ...sortedVal]
		}
		flatArr.push(...arr)
	}

	return (
		<Menu {...passedProps}>
			{
				flatArr.map((el, i) => {
					if (_.isString(el)) {
						return (
							<div key={i} className="fw-bold ms-2">{el}</div>
						)
					} else {
						if (renderMenuItemChildren) {
							return (
								<MenuItem key={i} option={el} position={i}>
									{renderMenuItemChildren(el, { ...menuProps, labelKey }, i)}
								</MenuItem>
							)
						}
						return (
							<MenuItem key={i} option={el} position={i}>{el.label ?? el.name}</MenuItem>
						)
					}
				})
			}
			{flatArr.length === 0 && !hasNextPage && <MenuItem position={0} disabled>{t("Ei tuloksia")}</MenuItem>}
			{hasNextPage && <div className="text-muted ms-2">{t("Lisätuloksia näkee kirjoittamalla hakutekstin")}</div>}
		</Menu>
	)
}

// Local constants for test group and subgroup sorting; used instead of enum query for now
const eTrainingGroup = [
	"antropometry",
	"physical",
	"sport_skill",
	"knowledge",
	"general",
	"muscle_balance"
]
const eTrainingSubGroup = [
	"lineaarinopeus",
	"suunnanmuutosnopeus",
	"nopeusvoima",
	"maksimi_ja_kestovoima",
	"kestävyys"
]

/**
 * TypeAhead `renderMenu` function for grouped tests
 */
export const renderMenuTestGroups = (results, menuProps, props) => {

	const { selected } = props

	// Remove menu props that are not compatible with 'div' DOM element.
	const passedProps = _.omit({ ...menuProps }, ["renderMenuItemChildren", "paginationText", "newSelectionPrefix"])

	// Create a flat array instead of rendering a nested loop to avoid render update max depth error
	const getFlatList = () => {
		if (!results) {
			return []
		}
		if (selected.length > 0) {
			results = results.filter(o => selected.some(item => item._id !== o._id))
		}
		let grouped = _.mapValues(_.groupBy(results, "group"), v => _.groupBy(v, "subGroup"))

		const flatList = []

		const sorted = sortByArray(_.keys(grouped), eTrainingGroup)
		sorted.forEach(primaryGroupKey => {

			if (primaryGroupKey !== "undefined") {
				flatList.push({ type: "primary", label: primaryGroupKey })
			}

			const secondaryGroupObj = grouped[primaryGroupKey]
			const sorted2nd = sortByArray(_.keys(secondaryGroupObj), eTrainingSubGroup)
			sorted2nd.forEach(secondaryGroupKey => {
				const testArray = secondaryGroupObj[secondaryGroupKey]
				if (secondaryGroupKey !== "undefined") {
					flatList.push({ type: "secondary", label: secondaryGroupKey })
				}
				const items = testArray.map(o => ({ type: "item", label: o.name, ...o }))
				flatList.push(...items)
			})
		})
		return flatList
	}

	const flatList = getFlatList()
	return (
		<Menu {...passedProps}>
			{
				flatList.map((el, i) => {
					if (el.type === "primary")
						return (
							<div key={i} className="fw-bold ms-1">{i18n.t(`values|trainingGroup~${el.label}`)}</div>
						)
					if (el.type === "secondary") {
						return (
							<div key={i} className="fw-bold fst-italic ms-2">{i18n.t(`values|trainingGroup~${el.label}`)}</div>
						)
					}
					if (el.type === "item") {
						return <MenuItem key={i} option={el} position={i}>{el.label}</MenuItem>
					}
				})
			}
			{flatList.length === 0 && <MenuItem position={0} disabled>{t("Ei tuloksia")}</MenuItem>}
		</Menu>
	)
}

/**
 * Returns the "highest" role for each event participant
 * 
 * @param {[Object]} participants 					Single Event GET participants array
 */
export const getEventParticipantWithHighestRole = (event, members = null) => {

	let participants = members ? members : event.participants

	// Filter out participants whose `profile` populated path is null
	participants = participants.filter(p => p.profile)

	const roleOrder = ROLE_ORDER

	let participantsWithRoles = participants.map(participant => {
		const participantRoles = participant.relativeRoles ?? participant.memberRoles

		const roleIndices = participantRoles.map(r => roleOrder.indexOf(r?.name)).filter(i => i !== -1)
		let roleIndex = Math.min(...roleIndices)
		let role = roleOrder[roleIndex]
		if (!role) {
			role = "none"
		}

		return {
			...participant,
			roleIndex,
			role
		}
	})

	return participantsWithRoles
}

/**
 * Returns applicable surface type options for specific test and specific operational class context.
 * 
 * @param {Object} opClass 				Operational class for current user or specific root group
 * @param {Object} test 					Training doc whose surface options are retrieved
 * @returns {Object}							Object with keys "surfaceDefault" and "surfaceChoices"
 */
export const getSurfaceOpts = (opClass, test) => {
	let surfaceChoices = test.surfaceChoices ?? []
	let surfaceDefault = test.surfaceType ?? ""

	const ctxOpts = _.get(opClass, "trainings")?.find(o => o.doc === test._id)?.options
	if (ctxOpts) {
		if (!_.isEmpty(ctxOpts.surfaceChoices)) {
			surfaceChoices = ctxOpts.surfaceChoices
		}
		if (ctxOpts.surfaceDefault) {
			surfaceDefault = ctxOpts.surfaceDefault
		}
	}

	return { surfaceChoices, surfaceDefault, surfaceType: surfaceDefault }
}

export const isParentEvent = event => {
	return ["team_tournament", "team_camp", "team_other"].includes(event?.compoundType?.[0]) ||
	["team_tournament", "team_camp", "team_other"].includes(event?.eventTypes?.[0])
}

/**
 * Applies filtering to input `useGetDistinctResultFields` data array to only include
 * items that exist `pageCustomizations.[].tests` array.
 * 
 * @param {[Object]} fields 					Array of data from `api/training/resultFields`
 * @param {[Object]} customTests 			Array of page customization objects from `opClass.pageCustomizations.[].tests`
 * @returns {[Object]}								Filtered array of only specific input field objects
 */
export const cFilterDistinctFields = (fields = [], customTests = []) => {
	if (_.isEmpty(customTests)) {
		return fields
	}

	const getMatch = (o) => fields.find(f => {
		const idMatch = f.testId === o.testId
		if (!idMatch) {
			return false
		}
		if (o.isGlobalSpecial && f.isGlobalSpecial) {
			return true
		}
		if (o.resultIndex != null) {
			return o.resultIndex === f.resultIndex
		}
		if (o.specialIndex != null) {
			return o.specialIndex === f.specialIndex
		}
		return f.resultIndex === 0
	})

	let filtered = customTests.map(o => {
		const match = getMatch(o)
		if (match) {
			let copy = { ...o, ...match }
			if (copy.children) {
				copy.children = copy.children.map(c => getMatch(c)).filter(o => o)
			}
			return copy
		}
	}).filter(o => o)
	return filtered
}

/**
 * Retrieves specific `useGetDistinctResultFields` data item based on
 * the input parameters.
 * 
 * @param {[Object]} fields 						Array of data from `api/training/resultFields`
 * @param {Object} input 								Input object with keys `testId`, `resultIndex`, `specialIndex` and `isGlobalSpecial`,
 * 																			or input string delimited by underscores. Contains interpolated values in the order listed above.
 * @returns {Object}										Found field object
 */
export const cGetField = (fields = [], input) => {
	let testId, resultIndex, specialIndex, isGlobalSpecial
	if (_.isObject(input)) {
		testId = input.testId
		resultIndex = input.resultIndex
		specialIndex = input.specialIndex
		isGlobalSpecial = input.isGlobalSpecial
	} else if (_.isString(input)) {
		const parts = input.split("_")
		testId = parts[0]

		resultIndex = parts[1]
		if (resultIndex === "" || resultIndex == null || resultIndex === "undefined") {
			resultIndex = null
		} else {
			resultIndex = parseInt(resultIndex)
		}

		specialIndex = parts[2]
		if (specialIndex === "" || specialIndex == null || specialIndex === "undefined") {
			specialIndex = null
		} else {
			specialIndex = parseInt(specialIndex)
		}

		isGlobalSpecial = !_.isEmpty(parts[3]) && parts[3] !== "undefined"
	} else {
		return fields[0]
	}


	const found = fields.find(f => {
		const idMatch = f.testId === testId
		if (!idMatch) {
			return false
		}
		if (isGlobalSpecial && f.isGlobalSpecial) {
			return true
		}
		if (resultIndex != null) {
			return resultIndex === f.resultIndex
		}
		if (specialIndex != null) {
			return specialIndex === f.specialIndex
		}
		return resultIndex === 0
	})
	return found
}

export const shouldAnswerPersonalDiary = (member, diaryTask) => {
	if (!member) {
		return false
	}
	const memberRoles = member?.relativeRoles?.map(o => o?.name) ?? []
	const rolesWPersonalDiary = _.get(diaryTask, ["taskDefinition", "templateRoleConfig", "hasPersonalDiary"])
	return memberRoles.some(r => rolesWPersonalDiary.includes(r))
}

export const shouldAnswerPeerDiary = (member, diaryTask) => {
	if (!member) {
		return false
	}
	const memberRoles = member?.relativeRoles?.map(o => o?.name) ?? []
	const rolesWPeerDiary = _.get(diaryTask, ["taskDefinition", "templateRoleConfig", "hasPeerDiaries"])
	return memberRoles.some(r => rolesWPeerDiary.includes(r))
}

export const getDiaryTaskStatusForMember = (member, diaryTasks) => {
	let status = null
	for (const diaryTask of diaryTasks) {
		const context = diaryTask.context ?? _.get(diaryTask, "entryCount.relevantContext")
		if (context === "peer") {
			if (shouldAnswerPeerDiary(member, diaryTask.diaryTask)) {
				const some = diaryTask.diaryAnswerers.some(pId => member.profile._id === pId)
				if (!some) return false
				else status = true
			}
		} else {
			if (shouldAnswerPersonalDiary(member, diaryTask.diaryTask)) {
				const some = diaryTask.diaryAnswerers.some(pId => member.profile._id === pId)
				if (!some) return false
				else status = true
			}
		}

	}
	return status
}

export const boolIcon = (bool) => {
	if (bool === true)
		return <Check color="green" size={24} />
	else if (bool === false)
		return <X color="red" size={24} />
	return <Dash size={24} />
}

export const getEventParticipantsWithSurveyStatuses = (event, task, context) => {
	const diaryAnswerers = task?.entryCount?.respondentIds ?? []
	const profileDoneMap = task.profileDoneMap
	let membersDataWithRoles = getEventParticipantWithHighestRole(event)
		.filter(m => shouldAnswerPeerDiary(m, task) || shouldAnswerPersonalDiary(m, task))
	const sortedMembers = [...membersDataWithRoles].map(m =>
		({ ...m, surveyAnswer: diaryAnswerers.includes(m.profile._id) || profileDoneMap[m.profile._id] })
	)
	sortedMembers.sort((a, b) => a?.profile?.firstname?.localeCompare(b?.profile?.firstname))
	sortedMembers.sort((a, b) => a?.profile?.lastname?.localeCompare(b?.profile?.lastname))
	return sortedMembers
}

/**
 * Counts elements that are evaluated true under `predicate`. Always returns a number.
 *
 * @param {Array.<T>} arr
 * @param {function(T):bool} predicate
 * @template T
 * @returns
 */
export const countMatching = (arr, predicate) => {
	let matches = 0
	for (const e of arr) {
		if (predicate(e)) {
			matches++
		}
	}

	return matches
}

export const convertHMS = (value) => {
	const sec = parseInt(value, 10) // convert value to number if it's string
	let hours = Math.floor(sec / 3600) // get hours
	let minutes = Math.floor((sec - hours * 3600) / 60) // get minutes
	let seconds = sec - hours * 3600 - minutes * 60 //  get seconds
	// add 0 if value < 10; Example: 2 => 02
	if (hours < 10) {
		hours = "0" + hours
	}
	if (minutes < 10) {
		minutes = "0" + minutes
	}
	if (seconds < 10) {
		seconds = "0" + seconds
	}
	return hours + ":" + minutes + ":" + seconds // Return is HH : MM : SS
}

/**
 * Return boolean on whether specific root group belongs to a customer group
 * based on their assigned customer group or class. Less specific customer groups
 * have more matches.
 * 
 * @param {Object} rootGroup 					Root Group which is checked. Must either have populated `customerGroups` path or `classes` path
 * @param {Object} customerGroup 			Customer group to which the root group is compared against. Must have id arrays of rgc documents.
 * @returns {Boolean}									Whether root group belongs to a customer group.
 */
export const isWithinCustomerGroup = (rootGroup, customerGroup) => {
	// Ensure that we have base amount of data required to compute boolean answer
	if ((!rootGroup?.customerGroups && !rootGroup?.classes) || !customerGroup?.operationalIds || _.isNil(customerGroup?.operationalIds?.length)) {
		return false
	}

	let opIds = _.flatMap(rootGroup.customerGroups, "operationalIds")
	if (_.isEmpty(opIds)) {
		opIds = rootGroup.classes.filter(c => c.type === "operational").map(o => o._id)
	}

	let proIds = _.flatMap(rootGroup.customerGroups, "processIds")
	if (_.isEmpty(proIds)) {
		proIds = rootGroup.classes.filter(c => c.type === "process").map(o => o._id)
	}

	let prdLvlIds = _.flatMap(rootGroup.customerGroups, "productLevelIds")
	if (_.isEmpty(prdLvlIds)) {
		prdLvlIds = rootGroup.classes.filter(c => c.type === "productLvl").map(o => o._id)
	}

	const cg = customerGroup
	if (cg.operationalIds.length > 0 && cg.processIds?.length > 0 && cg.productLevelIds?.length > 0) {
		return opIds.some(id => cg.operationalIds.includes(id)) && proIds.some(id => cg.processIds.includes(id)) && prdLvlIds.some(id => cg.productLevelIds.includes(id))
	}
	if (cg.operationalIds.length > 0 && cg.processIds?.length > 0) {
		return opIds.some(id => cg.operationalIds.includes(id)) && proIds.some(id => cg.processIds.includes(id))
	}
	if (cg.operationalIds.length > 0) {
		// Also compare by object since in some cases `cg.operationalIds` may be an object with field `_id`.
		return opIds.some(id => cg.operationalIds.includes(id) || cg.operationalIds.some((obj) => obj._id === id))
	}
	return true
}

export const getTestFieldIdStr = (testField) => {
	if (!testField) {
		return null
	}
	return `${testField.testId}_${testField.resultIndex}_${testField.specialIndex}_${testField.isGlobalSpecial}`
}

// Reused function for finding 'resultIndex-specialIndex-isGlobalSpecial' identifiable items from an array
export const getTestFieldMatch = (arr, testField, useFilter) => {
	if (!testField) {
		return useFilter ? [] : null
	}
	if (!arr) {
		arr = []
	}
	let fn = r => {
		if (testField.resultIndex != null) {
			return testField.resultIndex === r.resultIndex
		}
		if (testField.specialIndex != null) {
			return testField.specialIndex === r.specialIndex
		}
		if (testField.isGlobalSpecial && r.isGlobalSpecial) {
			return true
		}
		return r.resultIndex === 0
	}
	return useFilter ? arr.filter(fn) : arr.find(fn)
}

// Format bytes
export const formatBytes = (bytes, decimals = 2) => {
	if (!+bytes) return "0 Bytes"

	const k = 1000
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const embedTestFieldData = (fields, data) => {
	// Embed associated data into test field
	const formatField = (f) => {
		let newData = {}
		_.keys(data).forEach(profileId => {
			let meta = _.get(data, [profileId, "meta"])
			let testObj = _.get(data, [profileId, "tests", f.testId])
			let latestArr = _.get(testObj, "latestEntry")
			let testEntryLatest = getTestFieldMatch(latestArr, f)
			let previousArr = _.get(testObj, "previousEntry")
			let testEntryPrevious = getTestFieldMatch(previousArr, f)
			_.set(newData, profileId, { meta, latest: testEntryLatest, previous: testEntryPrevious })
		})
		return { ...f, profileData: newData }
	}


	// Embed associated data into test fields
	const getProcessedFields = (fields) => {
		let result = fields.map(f => {
			let r = formatField(f)
			if (r.children) {
				r.children = r.children.map(c => formatField(c))
			}
			return r
		})
		return result
	}

	return getProcessedFields(fields)
}

/**
 * Sets search param without re-rendering UI or reloading browser window.
 * 
 * When `value` is `undefined`, deletes `key` from params.
 * 
 * Useful for some operations that depend on search params while not wanting reactivity.
 */
export const setSearchParamNoReload = (key, value) => {
	const url = new URL(window.location.href)
	if (value === undefined) {
		url.searchParams.delete(key)
	} else {
		url.searchParams.set(key, value)
	}

	window.history.pushState(null, "", url.toString())
}

/**
 * Jumps to a video and highlights its background while taking pagination into account. Changes page to the page where that new video would be
 * 
 * When video is not jumpable (doesn't exist anymore for example) displays a toast error.
 * @param {*} param0 
 * @returns 
 */
export const highlightVideoEntry = ({ targetVideoId, t, videoPagination }) => {
	const tryHighlightVideo = (iteration = 0) => {
		try {
			const videoElement = document.getElementById(`video-${targetVideoId}`)

			if (!videoElement) {
				if (iteration >= 1) throw new Error(t("Alkuperäinen video ei ole enää saatavilla"))
				const { opts, setOpts, unpaginatedItems } = videoPagination
				// Try to find video from another pagination page
				const idx = unpaginatedItems.findIndex((item) => {
					return item._id === targetVideoId
				})

				if (idx === -1) throw new Error(t("Alkuperäinen video ei ole enää saatavilla"))

				// Pagination page should never be zero
				const newPage = Math.max(1, Math.ceil((idx + 1) / opts.limit))
				setOpts({ ...opts, page: newPage })
				return setTimeout(() => {
					return tryHighlightVideo(++iteration)
				}, 400)
			}

			videoElement.classList.add("highlighted-video-row")
			videoElement.scrollIntoView({ block: "center" })
			setSearchParamNoReload("id", targetVideoId)
			// Clear style from row after some time has passed
			return setTimeout(() => {
				const currentSearchParams = new URLSearchParams(window.location.search)
				if (currentSearchParams.get("id") === targetVideoId) {
					setSearchParamNoReload("id", undefined)
					videoElement.classList.remove("highlighted-video-row")
				}
			}, 2000)
		} catch (err) {
			toast.error(err?.message)
		}
	}

	tryHighlightVideo()
}

/**
 * Filters profiles for `<ProfileTypeahead />` in admin-interface using formik's values -parameters.
 * @param {{ linkedCustomerGroups, rootGroupsWithAccess, groupsWithAccess }} values - Formik values object that must have these fields as lists: **rootGroupsWithAccess**, **groupsWithAccess**.
 */
export const getSearchProfileEndpointQueryByVideoVisibilityFilters = (values) => {
	const { linkedCustomerGroups, rootGroupsWithAccess, groupsWithAccess, rolesWithAccess } = values

	const queryExtra = {
		...(!_.isEmpty(linkedCustomerGroups) && { customerGroupIds: linkedCustomerGroups.map((cg) => cg._id) }),
		...(!_.isEmpty(rootGroupsWithAccess) && { rootGroupIds: rootGroupsWithAccess.map((rg) => rg._id) }),
		...(!_.isEmpty(groupsWithAccess) && { groupIds: groupsWithAccess.map((g) => g._id) }),
		...(!_.isEmpty(rolesWithAccess) && { role: rolesWithAccess.map((r) => r.name) }),
	}

	return queryExtra
}

/**
 * Returns a list of all unique task names from event types and personal event types
 */
export const getAssignedTasks = (values) => {
	// Iterate event types and collect assigned tasks
	const d = values
	const tasks = []
	for (let et of d.eventTypes) {
		tasks.push(...et.tasks)
		for (let c of et.children) {
			tasks.push(...c.tasks)
		}
	}
	for (let et of d.personalEventTypes) {
		tasks.push(...et.tasks)
		for (let c of et.children) {
			tasks.push(...c.tasks)
		}
	}
	const uTasks = _.uniq(tasks)
	return uTasks
}
export const getTestDuplicatesInTasks = (tasks, trainingsData) => {
	if (!tasks) {
		return []
	}
	const singleTestTasks = tasks.filter(tsk => tsk.type === "test_results")
	const protocolTestTasks = tasks.filter(tsk => tsk.type === "test_results_protocol")
	const allTests = _.compact(singleTestTasks.map(tsk => tsk.doc).concat(_.flatMap(protocolTestTasks, "protocolTestSubset")))
	const duplicateTestIds = _.filter(allTests, (val, i, iteratee) => _.includes(iteratee, val, i + 1))
	if (!trainingsData) {
		return duplicateTestIds
	}
	const duplicateTestNames = duplicateTestIds.map(id => trainingsData?.find(o => o._id === id)).map(o => o.name)
	return duplicateTestNames
}

/** 
 * Util function to handle downloading of files into browser across web browsers.
 * Main way to do this is to use a `fetch` and then set the resulting blob to be downloaded via some DOM workarounds.
 * 
 * This main way DOES NOT work for Chrome. Seems to be related to parsing the blob from a CORS response type. A fallback catch will trigger if/when `fetch` fails, and the fallback method works for Chrome.
 * 
 * Both methods use DOM workarounds to be able to start the download process without opening a new tab or similar unwanted functionality.
 * 
 * Tested to work on these browsers at the time of writing (2024/10/10):
 * 	- Chrome 125
 *  - Firefox 131
 *  - Edge 129
 */
export const download = (URL) => {
	fetch(URL, { method: "GET" })
		.then((res) => res.blob())
		.then((blob) => {
			const url = window.URL.createObjectURL(blob)
			const a = document.createElement("a")
			a.style.display = "none"
			a.href = url
			a.download = "video"
			a.type = "video/mp4"
			document.body.appendChild(a)
			a.click()
			window.URL.revokeObjectURL(url)
			document.body.removeChild(a)
		})
		// Fallback to execute if `fetch` fails
		.catch(() => {
			const link = document.createElement("a")
			link.href = URL 
			link.download = "1" 
			link.target = "_blank" 
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		})
}
