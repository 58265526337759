import { useTranslation } from "react-i18next"

const DisclaimerText = () => {

	const { t } = useTranslation()

	return (
		<div className="d-flex">
			<div className="ms-auto text-muted" style={{ fontSize: "0.9em" }}>
				* {t("Arvo on määritetty koko vuoden ajalle eikä puolen vuoden jaksolle")}
			</div>
		</div>
	)
}


export default DisclaimerText