import { userSelector } from "@redux/user"
import { useSelector } from "react-redux"
import { getContextTreeAndDocs, getOverviewGroups, isWithinCustomerGroup } from "./utils"
import _ from "lodash"
import { useGetGroupByIdQuery, useGetRootGroupQuery, useSearchGroupsQuery, useSearchRootGroupsQuery } from "@api/group-api"
import { useGetRootGroupClassesQuery } from "@api/rootGroupClass-api"
import { useMemo, useState } from "react"
import { useGetTemplateRolesQuery } from "@api/templaterole-api"
import { useGetEventTypesQuery } from "@api/eventType-api"
import { useGetTaskDefinitionsQuery } from "@api/taskDefinition-api"
import { useListGeneralVideosQuery, useListRootGroupVideosQuery } from "../api/video-api"
import { loc, locData } from "./localization"

/**
 * Returns current profile's accessible groups, dependent on context.
 * 
 * Supports admin context settings; will perform a query in that case.
 */
export const useGroups = (opts={}) => {
	const { contextData, profileDataForCtx, selectedRole, profile } = useSelector(userSelector)
	const isAdmin = selectedRole === "admin"
	let { includeChildren, removePassive, limited } = opts
	if (limited === undefined) {
		limited = 1
	}

	// Data is queried only if the user is currently toggled as admin and is viewing a specific root group
	const queryCondition = contextData.adminToggle && isAdmin && contextData.rootGroup

	const { data } = useSearchGroupsQuery({ rootGroupId: contextData.rootGroup, pagination: false, limited, populateMembers: true }, { skip: !queryCondition })

	if (!profileDataForCtx)
		return []

	if (!contextData.rootGroup)
		return []

	if (queryCondition) {
		let res = data?.docs ?? []
		if (removePassive) {
			res = res.filter(g => !g.passive)
		}
		return res.map(g => ({ ...g, members: g.members.map(m => ({ ...m, profile: m.profile?._id ?? m.profile, profileName: m.profile?.name }))}))
	} else {
		let groupsInRoot = profileDataForCtx.groups?.filter(g => (g.root?._id ?? g.root) === contextData.rootGroup) ?? []
		let roleFiltered = groupsInRoot.filter(g => g.members.some(m => (m.profile?._id ?? m.profile) === profile && m.hasGroupRoles.some(gr => (gr?.name ?? gr) === contextData.groupRole)))

		if (includeChildren) {
			const viaChildren = _.uniqBy(_.flatMap(profileDataForCtx.children, c => getOverviewGroups(c.groupRoleOverview)), o => o?._id ?? o)
			roleFiltered.push(...viaChildren)
		}

		let res = roleFiltered
		if (removePassive) {
			res = res?.filter(g => !g.passive)
		}

		return res
	}
}

/**
 * Returns current profile's accessible root groups, dependent on context.
 * This is primarily determined from being in `rootGroup.memberPool` array.
 * 
 * Supports admin context settings; will perform a query in that case.
 */
export const useRootGroups = (opts={}) => {
	const { contextData, profileDataForCtx } = useSelector(userSelector)

	const { removePassive } = opts

	// Only run query when needed
	const dataCond = contextData.adminToggle && contextData.rootGroup
	const { data } = useGetRootGroupQuery({ id: contextData.rootGroup }, { skip: !dataCond })

	if (!profileDataForCtx)
		return []

	if (!contextData.rootGroup)
		return []

	if (dataCond) {
		return data ? [data] : []
	} else {
		let res = profileDataForCtx?.rootGroups ?? []
		if (removePassive) {
			res = res.filter(rg => !rg.passive)
		}
		return res
	}
}

/**
 * Returns current profile's accessible PATRONED root groups, dependent on context.
 * This is primarily determined from being in `rootGroup.patrons` array.
 * 
 * Supports admin context settings; will perform a query in that case.
 */
export const usePatronRootGroups = (opts={}) => {
	const { contextData, profileDataForCtx } = useSelector(userSelector)
	const isPatron = contextData.groupRole === "patron"

	// Only run query when needed
	const dataCond = contextData.adminToggle && contextData.rootGroup && isPatron
	const { data } = useGetRootGroupQuery({ id: contextData.rootGroup }, { skip: !dataCond })

	const { removePassive } = opts

	if (!profileDataForCtx) {
		return []
	}

	if (!contextData.rootGroup)
		return []

	if (dataCond) {
		return data ? [data] : []
	} else {
		let res = profileDataForCtx?.patronRootGroups ?? []
		if (removePassive) {
			return res = res.filter(rg => !rg.passive)
		}
		return res
	}
}

/**
 * Returns current profile's specific group, dependent on context
 * 
 * Supports admin context settings; will perform a query in that case.
 */
export const useCurGroup = () => {
	const { contextData } = useSelector(userSelector)
	const groups = useGroups()
	return groups.find(g => (g?._id ?? g) === contextData.group)
}

/**
 * Returns current profile's specific root group, dependent on context
 * 
 * Supports admin context settings; will perform a query in that case.
 */
export const useCurRootGroup = () => {
	const { contextData } = useSelector(userSelector)
	const rootGroups = useRootGroups()
	const patronRootGroups = usePatronRootGroups()
	return patronRootGroups.concat(rootGroups).find(rg => (rg?._id ?? rg) === contextData.rootGroup)
}

export const usePatronSubGroups = (opts={}) => {
	const { contextData } = useSelector(userSelector)
	const rootGroup = useCurRootGroup()
	const { removePassive } = opts
	const isPatron = contextData.groupRole === "patron"
	if (!isPatron || !rootGroup) {
		return []
	}
	let res = rootGroup?.groups ?? []
	if (removePassive) {
		res = res.filter(g => !g.passive)
	}
	return res
}

/**
 * Return `profileDataForCtx` object that contains dynamic context
 * from admin user's role selector.
 */
export const useMockAdminProfileData = () => {
	const { profileDataForCtx, selectedRole, contextData, profile } = useSelector(userSelector)
	const isAdmin = selectedRole === "admin"
	let groups = useGroups()
	const rootGroup = useCurRootGroup()
	const rootGroups = [rootGroup]
	const patronRootGroups = usePatronRootGroups()

	if (!profileDataForCtx) {
		return null
	}
	
	if (!isAdmin) {
		return profileDataForCtx
	}

	if (!rootGroup) {
		return profileDataForCtx
	}

	// Insert membership data
	if (contextData.groupRole === "patron") {
		rootGroup.patrons = [...rootGroup.patrons, { profile }]
	} else {
		let templateRole = rootGroup.templateRoles.find(tr => tr.name === contextData.groupRole)
		if (templateRole) {
			const copyGroups = _.cloneDeep(groups)
			copyGroups.forEach(g => {
				g.members.push({ profile, hasGroupRoles: [templateRole] })
			})
			groups = copyGroups
		}
	}

	let copy = _.clone(profileDataForCtx)
	Object.assign(copy, {
		groups,
		rootGroups,
		patronRootGroups
	})
	return copy
}

/**
 * Returns the currently chosen role definition
 */
export const useRoleDefinition = () => {

	const { contextData, selectedRole } = useSelector(userSelector)
	const isPlainAdmin = selectedRole === "admin" && !contextData.rootGroup
	const profileData = useMockAdminProfileData()
	const docMap = getContextTreeAndDocs(profileData, true)?.[1]
	if (isPlainAdmin) {
		return {
			name: "admin",
			title: "Ylläpitäjä",
			root: null,
			inGroups: [],
			rolePermMap: {},
			itemPerms: [],
			generalPerms: [],
			docMap
		}
	}

	const nullResponse = {
		name: "user",
		title: "Käyttäjä",
		root: contextData?.rootGroup,
		inGroups: [],
		rolePermMap: {},
		itemPerms: [],
		generalPerms: [],
		docMap
	}

	if (!contextData || !docMap)
		return nullResponse
	const rootGroupId = contextData.rootGroup
	const groupRoleName = contextData.groupRole
	const roleDef = _.get(docMap, [groupRoleName + "_" + rootGroupId])
	if (!roleDef) {
		return nullResponse
	}
	roleDef.docMap = docMap
	return roleDef
}

/**
 * Returns the current operational class.
 * 
 * Falls back to the current sport context, and then the first found
 * operational root group class.
 * 
 * If null is returned from the hook, it means that queries
 * are loading. If the return value is undefined, it means that no operational
 * classes exist in the database.
 * 
 * @param {String} rgId								Optional parameter for retrieving a specific root group's operational class instead of from own context
 * @returns {Object}									RootGroupClass document of type "operational"
 */
export const useOperationalClass = (rgId) => {

	rgId = rgId?._id ?? rgId

	const { contextData } = useSelector(userSelector)

	let rootGroupId = rgId ?? contextData.rootGroup
	const { data: rootData, isLoading: rootLoading } = useGetRootGroupQuery({ id: rootGroupId }, { skip: !rootGroupId})
	const { data: classData } = useGetRootGroupClassesQuery(null, { skip: rootLoading || rootData })

	if (!rootData && !classData) {
		return { eventTypes: [], personalEventTypes: []}
	}

	let opClass

	if (rootData) {
		opClass = rootData?.classes?.find(c => c.type === "operational")
	}

	if (!opClass) {
		opClass = classData?.find(c => c.type === "operational" && c.name === "football")
	}
	
	return opClass
}

/**
 * Returns the current operational class.
 * 
 * Falls back to the current sport context, and then the first found
 * operational root group class.
 * 
 * If null is returned from the hook, it means that queries
 * are loading. If the return value is undefined, it means that no operational
 * classes exist in the database.
 * 
 * @param {String} rgId								Optional parameter for retrieving a specific root group's operational class instead of from own context
 * @returns {Object}									RootGroupClass document of type "operational"
 */
export const useProcessClasses = (rgId) => {

	rgId = rgId?._id ?? rgId

	const { contextData } = useSelector(userSelector)

	let rootGroupId = rgId ?? contextData.rootGroup
	const { data: rootData } = useGetRootGroupQuery({ id: rootGroupId }, { skip: !rootGroupId})

	if (!rootData) {
		return []
	}

	let processClasses

	if (rootData) {
		processClasses = rootData?.classes?.filter(c => c.type === "process")
	}
	
	return processClasses ?? []
}

/**
 * Returns available task definitions for the current root group context or first available.
 * Doed not include universal task types. If all task definitions are needed,
 * use a GET query.
 * 
 * `null` return value means that root group class query is loading.
 * `undefined` means that no operational root group class was found in query results.
 */
export const useTaskDefinitions = () => {
	const opClass = useOperationalClass()

	if (opClass) {
		return null
	}

	let availableTasks = opClass.eventTypes.reduce((a,b) => {
		const eventType = b
		let tasks = [...eventType.tasks]
		let accumulatedDefs = tasks
		for (let c of eventType.children) {
			let tasks = [...c.tasks]
			accumulatedDefs.push(...tasks)
		}
		return a.concat(accumulatedDefs)
	}, [])

	let result = _.uniqBy(availableTasks, "name")
	return result
}

/**
 * Returns a mapping of dot-separated event types to available
 * task definitions. Will propagate parent event types' tasks to children automatically.
 * 
 * Will return null if needed data is not yet available.
 */
export const useEventTaskMap = () => {
	const { data: eventTypes } = useGetEventTypesQuery()
	const { data: taskDefData } = useGetTaskDefinitionsQuery()
	if (!eventTypes || !taskDefData) {
		return null
	}
	let obj = eventTypes.reduce((a,b) => {
		const item = b
		let itemTasks = item.tasks ?? []
		itemTasks = itemTasks.map(id => taskDefData.find(d => d._id === id)).filter(o => o)
		_.set(a, item.name, itemTasks)
		for (let c of item.children) {
			let childTasks = c.tasks ?? []
			childTasks = childTasks.map(id => taskDefData.find(d => d._id === id)).filter(o => o)
			_.set(a, [`${item.name}.${c.name}`], _.uniqBy([...itemTasks, ...childTasks], "name"))
		}
		return a
	}, {})

	return obj
}

/**
 * Returns two item array that has preferred dictionary based on current operational class,
 * and global dictionary of all name-title combinations.
 */
export const useDictionary = () => {
	const { dictionary } = useSelector(userSelector)
	let opClass = useOperationalClass()

	let preferredDictionary = dictionary
	let globalDictionary = dictionary

	if (opClass) {
		preferredDictionary = opClass.nameMap
	}

	return [preferredDictionary, globalDictionary]
}

/**
 * Returns function for displaying corresponding title for the supplied name value.
 * Prioritizes context-derived dictionary and falls back to global dictionary. If the value
 * is not in the global dictionary, the argument string is displayed as is.
 * 
 * @param {String} v						The name identifier 
 * @returns {Function}					The displayed title
 */
export const useDictionaryFn = () => {
	/*
	const [dic, globalDic] = useDictionary()
	return (name) => dic?.[name] || globalDic?.[name] || name
	*/

	const getEventTypeTitle =  useGetEventTypeTitle()
	return getEventTypeTitle
}

/**
 * Returns group options filtered by given rootgroups and customergroups array.
 * 
 * Useful for getting list of possible options as part of a filtering pipeline.
 * 
 * @param {{ rootGroups: object[], customerGroups: object[]}}
 */
export const useGroupOptionsForCustomerGroupsAndRootGroups = ({ customerGroups, rootGroups }) => {
	const { data: groups, isLoading } = useAllGroups()

	const getGroupOptions = (groups) => {
		return groups.map((group) => {
			if (
				rootGroups?.length > 0 &&
                !rootGroups?.some((r) => r?._id === group.root?._id)
			) {
				return undefined
			}

			if (customerGroups?.length > 0 && customerGroups.every((cg) => !isWithinCustomerGroup(group.root, cg))) {
				return undefined
			}

			return group
		})
	}

	return {
		isLoading,
		options: groups ? _.compact(getGroupOptions(groups)) : null
	}
}

/**
 * Handles processing of video data based on pagination options. Is also responsible for actually fetching the videolibrary videos based on the pagination options.
 * 
 * @param {{ isRootGroupView: boolean, customFilter: (video: object) => boolean }} 
 * 	Settings object. `isRootGroupView` determines what query to dispatch.
 * 	`customFilter` is an optional filter function applied after other filters.
 * 
 * @returns Object with important information such as whether query for videos is loading, 
 * the actual data response from the query, the pagination opts and finally the setter of the pagination options.
 */
export const useVideoPagination = (settings = { isRootGroupView: false, customFilter: undefined }) => {
	const { isRootGroupView } = settings
	const { contextData } = useSelector(userSelector)
	const [opts, setOpts] = useState({ 
		page: 1, 
		limit: 20, 
		search: "", 
		categories: [], 
		size: null, 
		rootGroupId: null, 
 		year: null, 
		month: null, 
		weeks: null,
	})

	const query = isRootGroupView ? useListRootGroupVideosQuery : useListGeneralVideosQuery
	const { data: pagRes, isLoading, isFetching } = query({ id: contextData.rootGroup, query: {
		paginate: true,
		...opts,
		month: opts.month !== null && !isNaN(opts.month) ? opts.month + 1 : null, // month is stored as zero-index
	}})

	/** Processes items by applying filtering  */
	const flattenVideos = () => {
		let data = _.cloneDeep(pagRes)

		if (pagRes?.docs) {
			let videos = _.cloneDeep(pagRes.docs)
			if (videos?.general && videos?.rootGroup) {
				const generalVids = pagRes?.docs?.general ?? []
				const rootGroupVids = pagRes?.docs?.rootGroup ?? []

				videos = generalVids.concat(rootGroupVids)
			}

			data.docs = videos
		}

		return data
	}

	return {
		data: flattenVideos(),
		isLoading,
		isFetching,
		opts,
		setOpts,
	}
}

/**
 * Returns all video categories user has access to. For admins, fetches all video categories across all operational classes. 
 * 
 * Ensures uniqueness.
 * @returns {string[]}
 */
export const useVideoCategories = () => {
	const { data: rootGroupClasses } = useGetRootGroupClassesQuery(null)
	const { contextData, isPlainAdmin } = useSelector(userSelector)
	const op = useOperationalClass(contextData.rootGroup)

	if (!op) return []

	return _(rootGroupClasses) 
		.filter((o) => (isPlainAdmin || o.name === op.name) && o.type === "operational")
		.map((o) => o?.videoCategories ?? [])
		.flattenDeep()
		.uniq()
		.value()
}

/**
 * Returns template role options for admin. Useful for situations where admin can select multiple template roles.
 * @returns 
 */
export const useTemplateRoleOptionsAdmin = () => {
	const { data: templateRoles, isLoading } = useGetTemplateRolesQuery(null)

	const getTemplateRoleOptions = () => {
		return _(templateRoles)
			.uniqBy("_id")
			.flatten()
			.value()
	}

	return {
		isLoading,
		options: getTemplateRoleOptions()
	}
}

/**
 * Returns rootgroups options filtered by given customergroups array.
 * 
 * Useful for getting list of possible options as part of a filtering pipeline.
 * 
 * @param {number[]} customerGroups - One or many customer group objects in array
 */
export const useRootGroupOptionsForCustomerGroups = (customerGroups) => {
	const { data: rootGroupData, isLoading } = useSearchRootGroupsQuery({
		pagination: false,
		limited: 1,
	})

	const getRootGroupOptions = (rootGroups) => {
		return rootGroups.map((rG) => {
			if (customerGroups?.length > 0) {
				if (customerGroups.every((cg) => !isWithinCustomerGroup(rG, cg))) {
					return undefined
				}
			}

			return { _id: rG?._id, name: rG?.name }
		})
	}

	return {
		isLoading,
		options: rootGroupData ? _.compact(getRootGroupOptions(rootGroupData.docs)) : null
	}

}

/**
 * Returns all groups under rootgroups fetched via `useSearchRootGroupsQuery`.
 * 
 * Useful for admin-level functionality since effectively returns all possible groups
 * 
 * @returns 
 */
export const useAllGroups = () => {
	const { data: rootGroupData, isLoading: isRootGroupsLoading } = useSearchRootGroupsQuery({
		pagination: false,
		limited: 1,
	})
	
	return {
		isLoading: isRootGroupsLoading,
		data: rootGroupData?.docs?.flatMap((o) => o.groups.map((g) => ({ ...g, groupHeader: o.name, root: o })))
	}
}

/**
 * Returns list of members of current group, optionally filtered by role name
 * @param {String} role 					Role name
 * @returns {[Object]}						Array of `group.members` path objects
 */
export const useMembersInGroup = (role, groupId) => {
	const { contextData, profileDataForCtx, isAdmin } = useSelector(userSelector)
	const curGroupId = groupId ?? contextData.group

	const { data: groupData, isLoading } = useGetGroupByIdQuery(curGroupId, { skip: !isAdmin || !curGroupId })

	if (!curGroupId || isLoading) {
		return []
	}

	const group = isAdmin ? groupData : _.get(profileDataForCtx, "groups")?.find(g => g._id === curGroupId)
	let members = group?.members ?? []
	if (role) {
		members = members.filter(m => m.hasGroupRoles.includes(role))
	}
	return members
}

/**
 * Checks presence of permission using `Profile.groupRoleOverview` path data.
 * This is dependent on the current root group context and admin status.
 * 
 * @param {String} resource 						Permission resource
 * @param {String} action 							Permission action
 * @param {String} targetRole 					Role identifier in `rolePermMap`
 * @param {Boolean} allowPatron 				Whether Patron should automatically have permission
 * @returns {Boolean}										Whether permission is possessed
 */
export const useRolePerm = (resource, action, targetRole, allowPatron=true) => {
	const { profileDataForCtx, contextData, isAdmin } = useSelector(userSelector)
	const isPlainAdmin = isAdmin && !contextData.rootGroup
	if (!profileDataForCtx) {
		return false
	}

	const rootObj = _.get(profileDataForCtx, ["groupRoleOverview", contextData.rootGroup])
	const hasPatron = _.get(rootObj, "isPatron") === true
	const hasPerm = _.get(rootObj, "memberRoles")?.some(mr => {
		return _.get(mr, ["rolePermMap", targetRole])?.some(p => p.resource === resource && p.actions.includes(action))
	})
	return isPlainAdmin || (allowPatron && hasPatron) || hasPerm
}


/**
 * Checks presence of permission using `Profile.groupRoleOverview` path data.
 * This is dependent on the current root group context and admin status.
 * 
 * @param {String} resource 						Permission resource
 * @param {String} action 							Permission action
 * @param {Boolean} allowPatron 				Whether Patron should automatically have permission
 * @returns {Boolean}										Whether permission is possessed
 */
export const useItemPerm = (resource, action, allowPatron=true) => {
	const { profileDataForCtx, contextData, isAdmin } = useSelector(userSelector)
	const isPlainAdmin = isAdmin && !contextData.rootGroup
	if (!profileDataForCtx) {
		return false
	}

	const rootObj = _.get(profileDataForCtx, ["groupRoleOverview", contextData.rootGroup])
	const hasPatron = _.get(rootObj, "isPatron") === true
	const hasPerm = _.get(rootObj, "memberRoles")?.some(mr => mr?.itemPerms?.some(p => p.resource === resource && p.actions.includes(action)))
	return isPlainAdmin || (allowPatron && hasPatron) || hasPerm
}


/**
 * Returns EventType documents from backend API and
 * allows listing EventType children at top level
 * 
 * @param {Boolean} flat			If true, includes EventType.children items at array top level
 * @returns {[Object]}				List of EventType documents
 */
export const useEventTypeDocs = (flat) => {
	const { data: data } = useGetEventTypesQuery()
	if (!data) {
		return []
	}
	if (!flat) {
		return data
	}
	const nested = _.flatMap(data, "children")
	return data.concat(nested)
}


/**
 * Returns function that allows retrieving EventType document based on
 * `name` identifier
 * 
 * @returns {Object}											EventType document
 */
export const useGetEventTypeDoc = () => {
	const allDocs = useEventTypeDocs()
	let getEventTypeDoc = (eventTypeName, parentEventName) => {
		return (parentEventName ? (allDocs.find(d => d.name === parentEventName)?.children ?? []) : allDocs).find(d => d.name === eventTypeName)
	}
	return getEventTypeDoc
}

/**
 * Returns function that allows retrieving EventType document title based on
 * `name` identifier
 * 
 * @returns {String}												EventType title
 */
export const useGetEventTypeTitle = () => {
	const getEventTypeDoc = useGetEventTypeDoc()
	return (eventTypeName, parentEventName) => getEventTypeDoc(eventTypeName, parentEventName)?.title ?? ""
}


/**
 * Returns exercise event types inherent to operational class, with the option to
 * also include event types present in 'durationsbytype' response data.
 * 
 * Return values may vary depending on the state of the event type list query.
 * 
 * @param {Object} keyedData 						"durationsbytype" endpoint response's 'data' attribute
 * @returns {Object}										Object with keys `guidedEventTypes`, `ownEventTypes` and query hook return values
 */
export const useGetExerciseEventTypes = (keyedData) => {
	
	const { data: eventTypeData, ...rest } = useGetEventTypesQuery(null, { skip: !keyedData })

	const opClass = useOperationalClass()
	const opClassGuidedEventTypes = opClass.eventTypes.filter(type => type.isExercise)
	const opClassOwnEventTypes = opClass.personalEventTypes.filter(type => type.isExercise)

	// Event type documents from response data keys
	const eventTypesInResponse = useMemo(() => {
		if (!keyedData || !eventTypeData) {
			return []
		}
		const d = keyedData
		let names = []
		for (let y in d) {
			for (let wk in d[y]) {
				const keys = _.keys(d[y][wk])
				names.push(...keys)
			}
		}
		names = _.uniq(names)
		const eventTypes = eventTypeData.filter(et => names.includes(et.name))
		return eventTypes
	}, [keyedData, eventTypeData])


	// The used event type collection will be union of op class base event types and foreign event types present in stat data response
	const responseGuidedEventTypes = eventTypesInResponse.filter(et => et.classes.some(c => c.name === "group"))
	const guidedEventTypes = _.unionBy(opClassGuidedEventTypes, responseGuidedEventTypes, o => o.name)

	const responseOwnEventTypes = eventTypesInResponse.filter(et => et.classes.some(c => c.name === "personal"))
	const ownEventTypes = _.unionBy(opClassOwnEventTypes, responseOwnEventTypes, o => o.name)

	return { guidedEventTypes, ownEventTypes, ...rest }
}
