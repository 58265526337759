import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import _ from "lodash"

import Http from "i18next-http-backend"
import BackendAdapter from "i18next-multiload-backend-adapter"
import LanguageDetector from "i18next-browser-languagedetector"
import { MULTILANGUAGE_SUPPORT_ENABLED, BACKEND_URL, IS_DEV } from "./const"

// Returns capitalized last key segment if no translation exists
const parseMissingKey = (key, capitalize) => {
	if (key === undefined)
		return key
	let reg = /[^~]*$/
	let match = key.match(reg)
	let str = ""
	if (match)
		str = match[0]
	else
		str = key
	if (capitalize)
		str = str.charAt(0).toUpperCase() + str.slice(1)
	let noUnderlines = str.replace("_", " ")
	return noUnderlines
}

if (MULTILANGUAGE_SUPPORT_ENABLED) {
	i18n.use(LanguageDetector)
}

i18n
	.use(BackendAdapter)
// for all options read: https://www.i18next.com/overview/configuration-options
	.use(initReactI18next)
	.init({
		backend: {
			backend: Http,
			backendOption: {
				loadPath: `${BACKEND_URL}/locales/resources.json?lng={{lng}}&ns={{ns}}`,
				addPath: `${BACKEND_URL}/locales/add/{{lng}}/{{ns}}`,
				allowMultiLoading: true,
			},
		},
		react: {
			useSuspense: true
		},
		// Sets default value as formatted last key segment automatically
		overloadTranslationOptionHandler: function(args) {
			let key = args[0]
			let opts = args[1]
			let result = parseMissingKey(key)
			return { ...opts, defaultValue: result } 
		},
		saveMissing: IS_DEV, // send not translated keys to endpoint
		supportedLngs: ["fi", "en"],
		fallbackLng: "fi",
		debug: IS_DEV, // Can be set to false if missing key console logs are too much
		keySeparator: "~",
		nsSeparator: "|",
		pluralSeparator: "§",
		interpolation: {
			escapeValue: false, // Allows rendering of special characters (/,",& etc.) instead of entity codes
		  },
		missingInterpolationHandler: (text, value, options) => {
			// Normalize interpolation variables to lowercase for case-insensitive matching of missing values
			const key = value[1]
			const lowerCaseKey = key?.toLowerCase()
			let lowerCaseOpts = _.mapKeys(options, (v,k) => k.toLowerCase())
			const newValue = _.get(lowerCaseOpts, lowerCaseKey)
			if (newValue === undefined) {
				// Returns {{var}} text as fallback if no match exists
				const interpText = value[0]
				return interpText
			}
			return newValue
		}
	})

i18n.loadNamespaces(["values", "terms"],
	(err, t) => {
		if (err)
			console.log(err)
		return t
	}
)

export default i18n