import React, { useMemo } from "react"
import { Col, Row } from "react-bootstrap"
import SurveyBarGraph from "./SurveyBarGraph"
import SurveyRadarGraph from "./SurveyRadarGraph"
import { useGetSurveyChartDataQuery } from "@api/diary-api"
import { useSelector } from "react-redux"
import { userSelector } from "@redux/user"
import Loading from "@views/Loading"
import _ from "lodash"
import { useGetSurveysExtQuery } from "@api/survey-api"
import i18n from "@/i18n"
import { localizeGetSurveyChartData } from "@utils/localization"

const now = new Date().toISOString()


const SurveyChangeTab = ({ pageName, groupIds, rowSize = 3 }) => {
	const { contextData } = useSelector(userSelector)
	const { data: _data, isLoading } = useGetSurveyChartDataQuery({ query: { groupIds: groupIds ?? contextData?.group, to: now, pageName } }, { refetchOnMountOrArgChange: true })
	const { data: surveyData, isLoading: surveyLoading } = useGetSurveysExtQuery({})
	
	const data = localizeGetSurveyChartData(_data, surveyData)

	// Create rows of three charts
	const rows = useMemo(() => {
		if (!data) {
			return []
		}

		return _.range(0, data.length, rowSize)
			.map((index) => data.slice(index, index + rowSize))
	}, [data])

	if (isLoading || surveyLoading) {
		return (
			<Loading />
		)
	}

	return (
		<>
			{rows.map((r, i) => (
				<Row key={i} style={{ minHeight: 400 }}>
					{r?.map(d => (
						<Col key={d.surveyName}>
							{d.type === "bar" && (
								<SurveyBarGraph data={d?.questionGroupAverages ?? d?.questionAverages ?? []} title={d.surveyName} transformAxis />
							)}
							{d.type === "polar" && (
								<SurveyRadarGraph data={d?.questionGroupAverages ?? d?.questionAverages ?? []} title={d.surveyName} />
							)}
						</Col>
					))}
				</Row>
			))}
		</>
	)
}

export default SurveyChangeTab