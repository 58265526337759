import { convertObjToQueryStr } from "@utils/utils"
import { api } from "./api"
import { locResponse } from "@api/utils"

export const surveyApi = api.injectEndpoints({
	endpoints: (builder) => ({


		// ===== BASIC SURVEYS =====

		getSurveys: builder.query({
			query: ({ query }) => ({
				url: `/survey${convertObjToQueryStr(query)}`,
			}),
			providesTags: (result, error, args) => {
				if (error)
					return []
				const tags = result.map(doc => ({ type: "Survey", id: (doc._id ?? doc.id) })).concat([{type: "Survey", id: "LIST"}, "Loc"])
				return tags
			},
			transformResponse: locResponse
		}),
		getSurvey: builder.query({
			query: ({ id }) => ({
				url: `/survey/${id}`,
			}),
			providesTags: (result, error, arg) => {
				return [{ type: "Survey", id: arg.id }]
			}
		}),
		createSurvey: builder.mutation({
			query: ({ body }) => ({
				url: "/survey",
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "Survey", id: "LIST" }]
			}
		}),
		updateSurvey: builder.mutation({
			query: ({ id, body }) => ({
				url: `/survey/${id}`,
				method: "PUT",
				body
			}),
			invalidatesTags: (result, error, args) => {
				const tags = [{ type: "Survey", id: "LIST" }, { type: "Survey", id: args.id}]
				return tags
			}
		}),
		removeSurvey: builder.mutation({
			query: ({ id, body }) => ({
				url: `/survey/${id}`,
				method: "DELETE",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "Survey", id: "LIST" }]
			}
		}),

		// ===== EXTERNAL SURVEYS =====

		getSurveysExt: builder.query({
			query: () => ({
				url: "/ext/survey",
			}),
			providesTags: (result, error, args) => {
				if (error)
					return []
				const tags = result.map(doc => ({ type: "Survey", id: (doc._id ?? doc.id) })).concat([{type: "Survey", id: "LIST"}])
				return tags
			}
		}),
		getSurveyExt: builder.query({
			query: ({ id }) => ({
				url: `/ext/survey/${id}`,
			}),
			providesTags: (result, error, arg) => {
				return [{ type: "Survey", id: arg.id }]
			}
		}),
		createSurveyExt: builder.mutation({
			query: ({ body }) => ({
				url: "/ext/survey",
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "Survey", id: "LIST" }]
			}
		}),
		updateSurveyExt: builder.mutation({
			query: ({ id, body }) => ({
				url: `/ext/survey/${id}`,
				method: "PUT",
				body
			}),
			invalidatesTags: (result, error, args) => {
				const tags = [{ type: "Survey", id: "LIST" }, { type: "Survey", id: args.id}]
				return tags
			}
		}),
		removeSurveyExt: builder.mutation({
			query: ({ id, body }) => ({
				url: `/ext/survey/${id}`,
				method: "DELETE",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "Survey", id: "LIST" }]
			}
		}),

		// ===== EXTERNAL SURVEY TYPES =====

		getSurveyTypesExt: builder.query({
			query: () => ({
				url: "/ext/surveyType",
			}),
			providesTags: (result, error, args) => {
				if (error)
					return []
				const tags = result.map(doc => ({ type: "SurveyType", id: (doc._id ?? doc.id) })).concat([{type: "Survey", id: "LIST"}])
				return tags
			}
		}),
		getSurveyTypeExt: builder.query({
			query: ({ id }) => ({
				url: `/ext/surveyType/${id}`,
			}),
			providesTags: (result, error, arg) => {
				return [{ type: "SurveyType", id: arg.id }]
			}
		}),
		createSurveyTypeExt: builder.mutation({
			query: ({ body }) => ({
				url: "/ext/surveyType",
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "SurveyType", id: "LIST" }]
			}
		}),
		updateSurveyTypeExt: builder.mutation({
			query: ({ id, body }) => ({
				url: `/ext/surveyType/${id}`,
				method: "PUT",
				body
			}),
			invalidatesTags: (result, error, args) => {
				const tags = [{ type: "SurveyType", id: "LIST" }, { type: "SurveyType", id: args.id}]
				return tags
			}
		}),
		removeSurveyTypeExt: builder.mutation({
			query: ({ id, body }) => ({
				url: `/ext/surveyType/${id}`,
				method: "DELETE",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "SurveyType", id: "LIST" }]
			}
		}),
	})
})

export const {
	useGetSurveysQuery,
	useGetSurveyQuery,
	useCreateSurveyMutation,
	useUpdateSurveyMutation,
	useRemoveSurveyMutation,
	useGetSurveysExtQuery,
	useGetSurveyExtQuery,
	useCreateSurveyExtMutation,
	useUpdateSurveyExtMutation,
	useRemoveSurveyExtMutation,
	useGetSurveyTypeExtQuery,
	useGetSurveyTypesExtQuery,
	useCreateSurveyTypeExtMutation,
	useUpdateSurveyTypeExtMutation,
	useRemoveSurveyTypeExtMutation,
} = surveyApi
