import { useGetClubSupportStatisticsOverviewQuery } from "@api/ffaClubSupportStatistics-api"
import Loading from "@views/Loading"
import { useMemo } from "react"
import _ from "lodash"
import ClubSupportOverviewTable from "../../components/FFAClubSupport/Dashboard/Overview/OverviewTable"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { userSelector } from "@redux/user"
import { Navigate } from "react-router-dom"

const FFAClubSupportDashboardOverview = () => {
	const { isAdmin, contextData, hasClubSupportAdmin } = useSelector(userSelector)
	const { t } = useTranslation()
	const { data: overviewStatistics, isLoading: isOverviewStatisticsLoading } = useGetClubSupportStatisticsOverviewQuery(
		{}, 
		{skip: !isAdmin && !hasClubSupportAdmin})

	const clubStatisticsKeyedByBucket = useMemo(() => {
		return _.groupBy(overviewStatistics, ({ rootGroup }) => rootGroup.bucketLevel)
	}, [isOverviewStatisticsLoading])

	if (!isAdmin && !hasClubSupportAdmin) {
		const ROUTE_PREFIX = "/ffa-club-support"
		return <Navigate to={`${ROUTE_PREFIX}/dashboard/${contextData.rootGroup}`} />
	}

	if (isOverviewStatisticsLoading) {
		return <Loading />
	}

	return (
		<div style={{ width: "100%" }}>
			<h1 className="mb-4">{t("Seuratukimalli Dashboard")}</h1>
			<ClubSupportOverviewTable data={clubStatisticsKeyedByBucket} />
		</div>
	)
}

export default FFAClubSupportDashboardOverview
