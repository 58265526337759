import { locData } from "@utils/localization"
import _ from "lodash"

/**
 * `transformResponse` function for localizing response data based on
 * top-level `translations` path if one exists
 * 
 * @param {Object} response 			Response data
 * @param {Object} meta						baseQuery meta property
 * @param {Object} arg						Query args; `noLocalization` property disables localization for the query
 * 
 * @returns {Object}							Response with paths modified
 */
export const locResponse = (response, meta, arg) => {
	if (arg?.noLocalization) {
		return response
	}
	return locData(response)
}

export const locResponseNested = (response, meta, arg, path) => {
	const baseResponse = locResponse(response, meta, arg)

	const handleItem = (item) => {
		const nested = _.get(item, path)
		if (nested) {
			let processedItem = { ...item }
			let value = locData(nested)
			_.set(processedItem, path, value)
			return processedItem
		} else {
			return item
		}
	}

	let result
	if (Array.isArray(baseResponse)) {
		result = baseResponse.map(o => handleItem(o))
	} else {
		result = handleItem(baseResponse)
	}
	return result
}