import { useTranslation } from "react-i18next"
import { UNIT_MAP } from "./utils/rules"

/**
 * Formats value keyed by `rule` from `club`'s values-object.
 * @param {*} param0 
 */
const DashboardTableValueFormatter = ({ rule, club, style={}, dataObj: _value, append, isPrevious }) => {
	const { t } = useTranslation()

	// Might not have answer to it in the object
	const valueObj = _value || club?.values?.[rule]
	if (!valueObj) {
		return <td style={style}>
			<span>
				-
			</span>
			{append}
		</td>
	}

	const { value, criteriaFilled } = valueObj
	const criteriaRule = valueObj?.rule

	let formattedValue

	if (typeof criteriaRule?.value === "boolean") {
		formattedValue = value ? t("Kyllä") : t("Ei")
	} else {
		formattedValue = value?.toString()
	}

	// Append unit if exists
	if (criteriaRule?.unit) formattedValue += ` ${UNIT_MAP(t)[criteriaRule.unit] ?? criteriaRule.unit}`

	const hasAsterisk = (criteriaRule?.yearBased && !isPrevious)
	if (hasAsterisk) formattedValue += "*"

	const getColor = () => {
		if (criteriaRule.noCriteria || (isPrevious && criteriaRule.yearBased)) {
			return undefined
		}
		if (criteriaFilled) {
			return "green"
		} else {
			return "red"
		}
	}
	
	return (
		<td style={style}>
			<span style={{ color: getColor() }} title={hasAsterisk ? t("Arvo on määritetty koko vuoden ajalle eikä puolen vuoden jaksolle") : undefined}>
				{formattedValue}
			</span>
			{append}
		</td>
	)
}

export default DashboardTableValueFormatter
