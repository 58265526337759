/**
 * Adapted from 'react-double-scrollbar' due to the npm package currently having a
 * broken component import.
 * 
 * NOTE: Will not respond to dynamically rendered content; primarily sets width
 * state on initial render and window resizes.
 */

import { useEffect, useRef, useState } from "react"

const DoubleScrollBar = ({ children, hideTopBar }) => {
	
	const outerDivRef = useRef()
	const childWrapperRef = useRef()

	const [widthState, setWidthState] = useState("0px")

	const calculateWidth = () => {

		let width = getChildWrapperWidth()

		if (width == null) {
			width = "auto"
		}

		// Set the width of the inner div to the first child's
		if (width !== widthState) {
			setWidthState(width)
		}
	}

	const getChildWrapperWidth = () => {
		let width = null
		if (childWrapperRef && childWrapperRef.current?.scrollWidth) {
			width = childWrapperRef.current.scrollWidth + "px"
		}
		return width
	}

	useEffect(() => {
		calculateWidth()
	}, [])

	useEffect(() => {
		window.addEventListener("resize", calculateWidth)
		if (outerDivRef.current) {
			outerDivRef.current.onscroll = function() {
				childWrapperRef.current.scrollLeft = outerDivRef.current.scrollLeft
			}
			childWrapperRef.current.onscroll = function() {
				outerDivRef.current.scrollLeft = childWrapperRef.current.scrollLeft
			}
		}
		return () => window.removeEventListener("resize", calculateWidth)
	}, [])

	let outerDivStyle = { overflowX: hideTopBar ? "hidden" : "auto", overflowY: "hidden" }
	let innerDivStyle = { height: "1px", width: widthState }
	let childDivStyle = { overflow: "auto", overflowY: "hidden" }

	return (
		<div>
			<div id="outerDiv" ref={outerDivRef} style={outerDivStyle}>
				<div id="innerDiv" style={innerDivStyle}>&nbsp;</div>
			</div>
			<div id="childWrapper" ref={childWrapperRef} style={childDivStyle} >
				{children}
			</div>
		</div>
	)

}

export default DoubleScrollBar