import { Form, Table } from "react-bootstrap"
import DashboardTableValueFormatter from "../TableValueFormatter"
import { useTranslation } from "react-i18next"
import { formatRuleCriteria } from "../utils/rules"
import { format, getMonth, getYear, startOfYear } from "date-fns"
import _ from "lodash"
import PositiveIntegerInput from "@components/Common/Inputs/PositiveIntegerInput"
import DisclaimerText from "../DisclaimerText"
import PlayerOverviewModal from "./PlayerOverviewModal"
import { useState } from "react"

const ClubSupportTable = ({ data, previous: _previous, edit, value, setValue }) => {

	const [clickedDataObj, setClickedDataObj] = useState()
	const [show, setShow] = useState()

	const handleOverviewClick = dataObj => {
		setClickedDataObj(dataObj)
		setShow(true)
	}

	return <>
		<Table size="sm" className="club-support-table">
			<tbody>
				{
					(data.sections || []).map((section, sectionIndex) => {

						let previous
						if (!section.columnDisplay.includes("previousSeason")) {
							previous = null
						} else {
							previous = _previous
						}

						const rows = []
						rows.push(
							<ClubSupportTableHeader 
								key={sectionIndex} 
								title={section.title} 
								currentPeriod={data.period} 
								previousPeriod={previous?.period}
								columnDisplay={section.columnDisplay}
							/>
						)
						rows.push(
							Object.keys(section.values).map((key, keyIndex, array) => {
								return (
									<ClubSupportTableRow 
										dataObj={section.values[key]}
										previousValue={previous?.sections[sectionIndex]?.values[key]}
										hasPreviousColumn={previous}
										isLastRow={keyIndex === array.length - 1}
										appendToValue={
											<ClubSupportTableValueLink 
												field={key}
												handleOverviewClick={() => handleOverviewClick(section.values[key])}
											/>}
										key={key}
										field={key}
										fieldValue={value[key] ?? ""}
										setFieldValue={v => setValue(prev => ({ ...prev, [key]: v }))}
										edit={edit}
									/>
								)})
						)					
						return rows
					})
				}
			</tbody>
		</Table>
		<PlayerOverviewModal show={show} handleClose={() => setShow(false)} dataObj={clickedDataObj} />
		<DisclaimerText />
	</>
}

const ClubSupportTableValueLink = ({ field, handleOverviewClick }) => {
	const { t } = useTranslation()

	const links = {
		academyPlayersTestSuccess: "/charts/group",
		talentPlayersTestSuccess: "/charts/group",
		academyPlayersTotalMinutes: handleOverviewClick,
		talentPlayersTotalMinutes: handleOverviewClick,
		academyPlayersPlayMinutesTotal: handleOverviewClick,
		talentPlayersPlayMinutesTotal: handleOverviewClick,
	}
	
	const link = links[field]
	const isFn = !_.isString(link)

	if ( link ) {
		return <div>
			<a 
				target="_blank"
				rel="noreferrer"
				href={isFn ? "#" : link} style={{ fontSize: "0.7em" }} 
				onClick={isFn ? (e) => { e.preventDefault();link() } : null}
				title={!isFn ? t("Avaa erillisen sivun") : ""}
			>
				{t("Katso tarkemmin")} 	
				{!isFn && <span> &#128279;</span>}
			</a>
		</div>
	}
}

const ClubSupportTableRow = ({ field, fieldValue, setFieldValue, dataObj, previousValue, hasPreviousColumn, isLastRow, appendToValue, edit }) => {
	const { t } = useTranslation()
	const style = isLastRow ? {paddingBottom: 50, borderBottom: 0} : undefined
	const { rule } = dataObj
	return <tr>
		<td style={{ ...style, paddingLeft: rule.indent ? rule.indent * 16 : undefined }}>{t(rule.label)}</td>
		<td style={style}>{formatRuleCriteria({ t, rule })}</td>
		{ hasPreviousColumn ? <DashboardTableValueFormatter style={style} dataObj={previousValue} isPrevious /> : <td style={style}></td>}
		{(edit && rule.adminAssigns) ?
			<ClubSupportTableInput edit={edit} field={field} fieldValue={fieldValue} setFieldValue={setFieldValue} dataObj={dataObj} /> :
			<DashboardTableValueFormatter style={style} dataObj={dataObj} append={appendToValue} />}
	</tr>
}

const ClubSupportTableInput = ({ field, fieldValue, setFieldValue={setFieldValue}, dataObj }) => {
	const { t } = useTranslation()
	const { rule } = dataObj
	const isBool = _.isBoolean(rule.value)
	if (isBool) {
		return <td>
			<Form.Group style={{maxWidth: 140}}>
				<Form.Check
					name={field + "true"}
					type="radio"
					inline
					label={t("Kyllä")}
					checked={fieldValue}
					onChange={e => setFieldValue(true)}
				/>
				<Form.Check
					name={field + "false"}
					type="radio"
					inline
					label={t("Ei")} 
					checked={!fieldValue}
					onChange={e => setFieldValue(false)}
				/>
			</Form.Group>
		</td>
	}
	return <td>
		<Form.Group style={{maxWidth: 140}}>
			<PositiveIntegerInput value={fieldValue} setValue={(v) => setFieldValue(v)} min={0} max={9999} />
		</Form.Group>
	</td>
}

const ClubSupportTableHeader = ({ title, currentPeriod, previousPeriod, columnDisplay }) => {
	const { t } = useTranslation()

	const getSeason = d => getMonth(d) < 6 ? t("Kevät") : t("Syksy")

	const getDateRangeDisplay = () => {
		if (!columnDisplay.includes("previousSeason")) {
			return null
		}
		return (
			<div style={{ fontSize: "0.7em" }}>
				{columnDisplay.includes("currentYear") ? 
					<span>{format(startOfYear(new Date()), "dd.MM.yyyy")} - {format(new Date(), "dd.MM.yyyy")}</span> : 
					<span>{format(new Date(currentPeriod.from), "dd.MM.yyyy")} - {format(new Date(currentPeriod.to), "dd.MM.yyyy")}</span>}
			</div>
		)
	}

	const titleStyle = {fontWeight: "500"}
	return <tr>
		<th style={ titleStyle }>{t(title)}</th>
		<th style={ titleStyle }>{t("Kriteeri")}</th>
		{ 
			previousPeriod ?
				<th style={ titleStyle }>
					{columnDisplay.includes("currentYear") ? "" : `${getSeason(new Date(previousPeriod.to))} ${getYear(new Date(previousPeriod.to))}`}
					{<div style={{ fontSize: "0.7em" }}>
						{format(new Date(previousPeriod.from), "dd.MM.yyyy")} - {format(new Date(previousPeriod.to), "dd.MM.yyyy")}
					</div>}
				</th> :
				<th>
				</th>
		}
		<th style={ titleStyle }>
			{columnDisplay.includes("currentYear") ? t("Vuosi {{y}}", { y: getYear(new Date(currentPeriod.to)) }) : `${getSeason(new Date(currentPeriod.to))} ${getYear(new Date(currentPeriod.to))}`}
			{getDateRangeDisplay()}
		</th>
	</tr>
}

export default ClubSupportTable