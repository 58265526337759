import _ from "lodash"
import React from "react"
import DashboardTableValueFormatter from "../TableValueFormatter"
import ClubTitleHeader from "../ClubTitleHeader"
import { useTranslation } from "react-i18next"
import { Table } from "react-bootstrap"
import DisclaimerText from "../DisclaimerText"
import DoubleScrollBar from "@components/Common/DoubleScrollBar"

/** 
 * Represents actual table interface for the overview dashboard. 
*/
const ClubSupportOverviewTable = ({ data }) => {

	const { t } = useTranslation()

	const renderBody = ({ values }) => {
		const renderRuleRow = (isIndented, sectionIndex, ruleKey) => {
			const label = _.get(values, [0, "sections", sectionIndex, "values", ruleKey, "rule", "label"])
			const N_CLUBS = values.length
	
			const cols = []
			const indent = _.get(values, [0, "sections", sectionIndex, "values", ruleKey, "rule", "indent"])
			cols.push(<td key={sectionIndex+"_label"} style={{ paddingLeft: indent ? indent*16 : (isIndented ? 8 : 0) }} className="sticky-column-no-border">{label}</td>)
			for (let i = 0; i < N_CLUBS; i++) {
				const actualRule = _.get(values, [i, "sections", sectionIndex, "values", ruleKey])
				cols.push(<DashboardTableValueFormatter
					key={sectionIndex+"_val_"+i}
					dataObj={actualRule} 
				/>)
			}

			return (
				<tr key={sectionIndex+"_row_"+ruleKey}>
					{cols}
				</tr>
			)
		}

		return values[0].sections.map((section, index) => {
			return <React.Fragment key={index}>
				{section?.title ? <tr style={{ fontWeight: "500", fontSize: 16 }}><td className="sticky-column-no-border">{section.title}</td></tr> : <tr />}
				{Object.keys(section.values).map((ruleKey) => renderRuleRow(!!section?.title, index, ruleKey))}
				{/* Spacer to create gap between different sections */}
				<tr style={{ height: 20}} />
			</React.Fragment>
		})
	}

	const LABEL_COL_MIN_WIDTH = 400
	const VALUE_COL_MIN_WIDTH = 200

	return (
		<div>
			{Object.entries(data).map(([bucketLevel, values], i) => {
				return (
					<div key={i} className="border rounded p-3 mb-3">
						<DoubleScrollBar>
							<Table>
								<colgroup>
									<col
										width={`${(1 / values.length - 1) * 100}%`}
										style={{ minWidth: LABEL_COL_MIN_WIDTH }}
									/>
									{_.range(0, values.length).map((_, i) => <col
										key={i}
										width={"20%"}
										style={{ minWidth: VALUE_COL_MIN_WIDTH }}
									/>)}
								</colgroup>
								<thead>
									<tr>
										<th className="sticky-column-no-border" style={{ fontSize: 18 }}>{t("Kori")} {bucketLevel}</th>
										{values.map((v, i) => <ClubTitleHeader key={i} club={v} />)}
									</tr>
								</thead>
								<tbody>
									{renderBody({ bucketLevel, values })}
								</tbody>
							</Table>
						</DoubleScrollBar>
					</div>
				)
			})}
			<DisclaimerText />
		</div>
	)
}

export default ClubSupportOverviewTable
